import React, { useState } from "react";
import { Row, Col, Button, Form, Input } from "antd";
import { useHistory } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";

const { TextArea } = Input;

const RateOrder = () => {
  const history = useHistory();
  const [prodRating, setProdrating] = useState(0);
  const [serviceRating, setServiceRating] = useState(0);

  const handleProductRating = (rate) => {
    setProdrating(rate);
  };

  const handleServiceRating = (rate) => {
    setServiceRating(rate);
  };

  const handleFeedback = (values) => {
    console.log("values", values);
    history.push("/home");
  };

  const backToHome = () => {
    history.push("/home");
  };

  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
      </div>
      <div className="mobile-hide">
      <Scrollbars autoHide className="prodCardsScrollDesk">
        <div className="orderHistoryPageDesk">
          <Row style={{ marginBottom: 10 }}>
            <Col span={2} style={{ alignSelf: "center" }}>
              <img
                src={LeftArrow}
                alt=""
                className="arrowLeft"
                onClick={backToHome}
              />
            </Col>
            <Col span={22}>
              <p className="cartTitleDesk">Give Feedback</p>
            </Col>
          </Row>
          <div className="rateOrderDesk">
            <Form onFinish={handleFeedback} name="feedbackForm">
              <p className="howWasFood">How was the food?</p>
              <Row style={{ marginBottom: 5 }}>
                <Col span={10}>
                  <Rating
                    onClick={handleProductRating}
                    ratingValue={prodRating}
                    size={22}
                    allowHover={false}
                  />
                </Col>
                <Col span={14} />
              </Row>
              <p className="howWasFood">How was the service provided?</p>
              <Row style={{ marginBottom: 5 }}>
                <Col span={10}>
                  <Rating
                    onClick={handleServiceRating}
                    ratingValue={serviceRating}
                    size={22}
                    allowHover={false}
                  />
                </Col>
                <Col span={14} />
              </Row>
              <p className="howWasFood">Care to share more about the food</p>
              <Form.Item
                name="aboutFood"
                // label="Care to share more about the food"
                className="feedbackFormItem"
              >
                <TextArea rows={4} className="feedbackTextareaDesk" />
              </Form.Item>
              <p className="howWasFood">Care to share more about the service</p>
              <Form.Item
                name="aboutServices"
                // label="Care to share more about the service"
                className="feedbackFormItem"
              >
                <TextArea rows={4} className="feedbackTextareaDesk" />
              </Form.Item>
              <div className="nextBtnDivInCartDesk">
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    size="large"
                    htmlType="submit"
                    className="submitFeedbackBtnDesk"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
        <div className="prodFooter">
        <FooterComponent />
        </div>
        </Scrollbars>
      </div>
      <div className="desktop-hide">
        <div className="cartMain">
          <Row>
            <Col span={24}>
              <img
                src={LeftArrow}
                alt=""
                className="arrowLeft"
                onClick={backToHome}
              />
            </Col>
          </Row>
          <Form onFinish={handleFeedback} name="feedbackForm">
            <p className="giveFeedback">Give Feedback</p>
            <p className="howWasFood">How was the food?</p>
            <Row style={{ marginBottom: 5 }}>
              <Col span={10}>
                <Rating
                  onClick={handleProductRating}
                  ratingValue={prodRating}
                  size={22}
                  allowHover={false}
                />
              </Col>
              <Col span={14} />
            </Row>
            <p className="howWasFood">How was the service provided?</p>
            <Row style={{ marginBottom: 5 }}>
              <Col span={10}>
                <Rating
                  onClick={handleServiceRating}
                  ratingValue={serviceRating}
                  size={22}
                  allowHover={false}
                />
              </Col>
              <Col span={14} />
            </Row>
            <Form.Item
              name="aboutFood"
              label="Care to share more about the food"
              className="feedbackFormItem"
            >
              <TextArea rows={2} className="feedbackTextarea" />
            </Form.Item>
            <Form.Item
              name="aboutServices"
              label="Care to share more about the service"
              className="feedbackFormItem"
            >
              <TextArea rows={2} className="feedbackTextarea" />
            </Form.Item>
            <div className="nextBtnDivInCart">
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  size="large"
                  htmlType="submit"
                  className="submitFeedbackBtn"
                >
                  Submit Feedback
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <MenuComponent />
      </div>
    </div>
  );
};

export default RateOrder;
