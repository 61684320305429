import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Radio,
  message
} from "antd";
import Axios from "axios";
import { fnbURL } from "../../../src/constants/config";
import { useHistory } from "react-router-dom";
import moment from "moment";
import LeftArrow from "../../assets/images/leftArrow.svg";
import Wallet from "../../assets/images/wallet-payment.svg";
import Razorpay from "../../assets/images/razorpay.svg";
import MenuComponent from "../menuComponent";

const Payment = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [cartTotal, setCartTotal] = useState([]);
  const [cart, setCart] = useState({
    lineItems: [],
    total: 0,
    tax: 0,
    totalQty: 0,
  });
  const [storeId, setStoreId] = useState("");

  useEffect(() => {
    const restaurantId = localStorage.getItem("restaurantId");
    const cartList = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          lineItems: [],
          total: 0,
          tax: 0,
          totalQty: 0,
        };
    setCartTotal(cartList);
    setCart(cartList);
    setStoreId(restaurantId);
  }, []);

  const handleAddMoney = () => {
    history.push("/wallet");
  }

  const [paymentName, setPaymentName] = useState("")

  const handlePaymentSelection = (value) => {
    if(value === "wallet") {
      setPaymentName(value);
    } else if (value === "razorpayment") {
      setPaymentName(value);
    }
  }

const handleNext = () => {
  if (paymentName === "razorpayment") {
    handleRazorpay();
  } else if (paymentName === "wallet") {
    history.push("/thanks");
  }
}

  const handleRazorpay = () => {
    if (cart.lineItems.length > 0) {
      const subtotalAmount = cart.total;
      const mobileNumber = localStorage.getItem("phoneNumber");
      const totalAmount = subtotalAmount;
      const val = Math.floor(1000 + Math.random() * 9000);
      const date = new Date();
      const formatteddate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
      let name = "";
      let productCode = "";
      let quantity = "";
      let price = "";
      let subTotal = "";
      //  let subtotalTax = ''
      let total = "";
      //  let totalTax = ''
      const arrayForMutation = [];
      const itemLength = cart.lineItems.length;
      localStorage.setItem("totalProducts", itemLength);
      for (let index = 0; index < cart.lineItems.length; index++) {
        // const element = cart.lineItems[index];
        name = cart.lineItems[index].name;
        productCode = cart.lineItems[index].prodCode;
        quantity = cart.lineItems[index].weight;
        price = cart.lineItems[index].price;
        subTotal = cart.lineItems[index].price;
        total = cart.lineItems[index].price;
        const selectedAddons =
          cart.lineItems[index].add_on_group === undefined ||
          cart.lineItems[index].add_on_group === null
            ? []
            : cart.lineItems[index].add_on_group;
        let addonsArray = [];
        selectedAddons.map((data) => {
          addonsArray.push(data);
          return null;
        });
        let newArray = [];
        for (let index = 0; index < addonsArray.length; index++) {
          const addonname = addonsArray[index].name;
          const id = addonsArray[index].id;
          const price = addonsArray[index].price;
          newArray.push(
            `{
          id :"${id}"
          name:"${addonname}"
          price:"${price}"
        }`
          );
        }
        arrayForMutation.push(
          `{
          name: "${name}"
          productCode: "${productCode}"
          quantity: ${quantity}
          price: ${price}
          subTotal: ${subTotal}
          subtotalTax: 0.00
          total: ${total}
          totalTax: 0.00
          addOnProducts: [${newArray}]
        }`
        );
      }
      const getBookinOrder = {
        query: `mutation {
        createOrder(
            order: {
                orderNo: "${val}"
                createdVia: "rest-api"
                storeId: "${storeId}"
                status: "NEW"
                currency: "INR"
                dateCreated: "${formatteddate}"
                discountTotal: 0.0
                discountTax: 0.0
                cartTax: 0.0
                total: ${totalAmount}
                totalTax: 0
                pricesIncludeTax: "false"
                mobileNo: ${mobileNumber}
                customerIpAddress: ""
                customerNote: ""
                paymentMethod: "razorpay"
                paymentMethodTitle: "Credit Card/Debit Card/NetBanking"
                transactionId: ""
                cartHash: ""
                metaData: [{ key: "_dokan_vendor_id", value: "1" }]
                lineItems: [${arrayForMutation}]
            }
        ) {
            status
            message
            cwcOrderId
            razorpayOrderId
        }
    }`,
      };
      Axios({
        url: fnbURL,
        method: "POST",
        data: getBookinOrder,
        headers: {
          clientId: "39BC576048054849BFBFEDBF29142A3E",
        },
      }).then((response) => {
        const respose = response.data.data.createOrder;
        if (respose.message === "Order created successfully.") {
          const razorPayOrderId =
            response.data.data.createOrder.razorpayOrderId;
          const cwcOrderId = response.data.data.createOrder.cwcOrderId;
          // do payment for successful orders
          const options = {
            key: "rzp_test_3sW37wBTmaMDnK",
            amount: `${totalAmount * 100}`,
            currency: "INR",
            name: "CW Food Ordering",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: `${razorPayOrderId}`,
            prefill: {
              name: "Gaurav Kumar",
              email: "gaurav.kumar@example.com",
              contact: "9999999999",
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            handler: (response) => verifyPayment(cwcOrderId, response),
            theme: {
              color: "#3399cc",
            },
          };
          var rzp1 = new window.Razorpay(options);
          rzp1.open();
        } else {
          message.error("Failed to Place Order");
        }
      });
    } else {
      message.error("No Products in cart!");
    }
  };

  const verifyPayment = (cwcOrderId, response) => {
    setLoading(true);
    const verifyPayment = {
      query: `mutation {
        verifyOrderPayment(
            razorPayOrder: {
                cwcOrderId: "${cwcOrderId}"
                razorpayOrderId: "${response.razorpay_order_id}"
                razorpayPaymentId: "${response.razorpay_payment_id}"
                razorpaySignature: "${response.razorpay_signature}"
            }
        ) {
            status
            message
            cwcOrderId
            razorpayOrderId
            razorpayPaymentId
            razorpaySignature
        }
    }`,
    };
    Axios({
      url: fnbURL,
      method: "POST",
      data: verifyPayment,
      headers: {
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      if (
        response.data.data.verifyOrderPayment.message ===
        "Payment validated successfully."
      ) {
        const date = new Date();
        const formatteddate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
        localStorage.setItem("cwcOrderId", cwcOrderId);
        localStorage.setItem("orderDate", formatteddate);
        history.push("/thanks");
        setLoading(false);
        setCart({});
      }
    });
  };

  return (
    <div>
      <div className="cartMain">
        {/* <Scrollbars autoHide className="cartSroll"> */}
        <Row>
          <Col span={10}>
            <img src={LeftArrow} alt="" className="arrowLeft" />
          </Col>
          <Col span={14}>
            <p className="cartTitle">Payment</p>
          </Col>
        </Row>
        <div style={{ marginTop: 20 }}>
          <Card bodyStyle={{ padding: 20 }} className="restDetailsCardInCart">
            <Row>
              <Col span={12}>
                <p className="totalAmount">Total Amount</p>
              </Col>
              <Col span={12}>
                <p className="totalAmountValue">Rs.{cartTotal.total || 0}</p>
              </Col>
            </Row>
          </Card>
        </div>
        <p className="choosePayment">Choose Payment Method</p>
        <div>
        <Radio.Group
                      onChange={(e) => handlePaymentSelection(e.target.value)}
                      style={{ width: "100%" }}
                    >
                      <div style={{ padding: "0px 5px" }}>
              <Card bodyStyle={{ padding: 15 }} className="paymentCard">
                <Row>
                  <Col span={6}>
                    <img src={Wallet} alt="" />
                  </Col>
                  <Col span={10} style={{ alignSelf: "center" }}>
                    <p className="paymentType">Wallet</p>
                  </Col>
                  <Col span={6} style={{ alignSelf: "center" }}>
                    <p className="walletPaymentBal">&#8377; 2378.96</p>
                  </Col>
                  <Col span={2} style={{ alignSelf: "center" }}>
                    <Radio value="wallet" className="paymentSelect" />
                  </Col>
                </Row>
                <Row>
                  <Col span={6} />
                  <Col span={10} />
                  <Col span={6}>
                  <Card bodyStyle={{ padding: 0 }} className="topup-activecard">
                    <p className="add-money" onClick={handleAddMoney}>Add Money</p>
                  </Card>
                  </Col>
                  <Col span={2} />
                </Row>
              </Card>
            </div>
            <div style={{ padding: "0px 5px" }}>
              <Card bodyStyle={{ padding: 15 }} className="paymentCard">
              <Row>
                  <Col span={6}>
                  <img src={Razorpay} alt="" />
                  </Col>
                  <Col span={14} style={{ alignSelf: "center" }}>
                  <p className="paymentType">(Credit card/debit card etc)</p>
                  </Col>
                  <Col span={2} style={{ alignSelf: "center" }} />
                  <Col span={2} style={{ alignSelf: "center" }}>
                    <Radio value="razorpayment" className="paymentSelect" />
                  </Col>
                </Row>
              </Card>
            </div>
                    </Radio.Group>
            
            
        </div>
        <div className="nextBtnDivInCart">
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              size="large"
              htmlType="submit"
              className="nextBtnInPayment"
              onClick={handleNext}
            >
              Next
            </Button>
          </Form.Item>
        </div>
        {/* </Scrollbars> */}
      </div>
      <MenuComponent />
    </div>
  );
};

export default Payment;
