import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Row, Col, Spin } from "antd";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { fnbURL } from "../../../src/constants/config";
import "antd/dist/antd.css";
import "../styles.css";
import UserImg from "../../assets/images/user.png";
import LoginGif from "../../assets/images/login.gif";

const Login = () => {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerDetails, setCustomerDetails] = useState({
    onNext: true,
    verifyCustomer: {},
    realDigits: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem(
        "cart",
        JSON.stringify({
          lineItems: [],
          total: 0,
          tax: 0,
          totalQty: 0,
        })
      );
      localStorage.setItem("itemsQuantity", JSON.stringify(0));
    }, 500);
  }, []);

  const handleLogin = (values) => {
    setLoading(true);
    if (values !== undefined) {
      let phNumber = values.phoneNumber;
      localStorage.setItem("phoneNumber", JSON.stringify(phNumber));
      const getStore = {
        query: `mutation {
          verifyCustomer(contactNo: "${phNumber}") {
              otp
              token
              b2cCustomerId
              newCustomer
              firstname
              lastname
              email
              walletId
          }
      }`,
      };

      Axios({
        url: fnbURL,
        method: "POST",
        async: true,
        crossDomain: true,
        data: getStore,
        headers: {
          clientId: "39BC576048054849BFBFEDBF29142A3E",
        },
      }).then((response) => {
        console.log("response", response);
        if (response.data.data !== null) {
          const verifyCustomer = response.data.data.verifyCustomer;
          if (verifyCustomer !== null) {
            let customerId = verifyCustomer.b2cCustomerId;
            let num = verifyCustomer.otp;
            let digits = num.toString().split("");
            let walletId = verifyCustomer.walletId;
            const realDigits = digits.map(Number);
            const clientToken = verifyCustomer.token;
            localStorage.setItem("clientToken", JSON.stringify(clientToken));
            localStorage.setItem(
              "verifyCustomer",
              JSON.stringify(verifyCustomer)
            );
            localStorage.setItem("customerId", JSON.stringify(customerId));
            localStorage.setItem("walletId", JSON.stringify(walletId));
            setPhoneNumber(phNumber);
            setCustomerDetails({
              onNext: false,
              verifyCustomer: verifyCustomer,
              realDigits: realDigits,
              clientToken: clientToken,
            });
            setLoading(false);
          } else {
            message.error("Invalid response please check once");
            setLoading(false);
          }
        } else {
          message.error("Invalid response please check once");
          setLoading(false);
        }
      });
    }
  };

  const onVerifyOTP = (values) => {
    setLoading(true);
    if (customerDetails.verifyCustomer.newCustomer === true) {
      setTimeout(() => {
        history.push("/signup");
      }, 500);
    } else {
      const userInfo = {
        email: `${customerDetails.verifyCustomer.email}`,
        firstName: `${customerDetails.verifyCustomer.firstname}`,
        lastName: `${customerDetails.verifyCustomer.lastname}`,
      };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      history.push("/home");
    }
  };

  const antIcon = <LoadingOutlined spin />;

  return (
    <Spin
      spinning={loading}
      tip="Loading..."
      indicator={antIcon}
      style={{
        position: "relative",
        top: "50vh",
        color: "#ff6339",
      }}
      size="large"
    >
      <div className="mobile-hide">
        <Row style={{ height: "100vh" }}>
          <Col span={12} className="loginFormColDesk">
            <p className="welcomeText">Welcome</p>
            <div className="loginMainDesk">
              {customerDetails.onNext ? (
                <div>
                  <img src={UserImg} alt="user icon" className="userIconDesk" />
                  <div className="textDivDesk">
                    <p className="enterText">
                      Enter your mobile number to start ordering
                    </p>
                    <p className="confirmationText">
                      We will send you a confirmation code
                    </p>
                    <Form name="inputForm" onFinish={handleLogin}>
                      <label className="inputLabelDesk">Mobile</label>
                      <Form.Item
                        name="phoneNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please input your mobile number!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          // prefix={"(+91)"}
                          maxLength={10}
                          value={phoneNumber}
                          size="large"
                          className="mobileInputDesk"
                          autoComplete="off"
                        />
                      </Form.Item>
                      <div className="nextBtnDivDesk">
                        <Form.Item>
                          <Button
                            size="large"
                            htmlType="submit"
                            className="nextBtnDesk"
                          >
                            Next
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : (
                <div>
                  <img src={UserImg} alt="user icon" className="userIconDesk" />
                  <div className="otpTextDiv">
                    <p className="enterCodeText">
                      Enter code sent to your number
                    </p>
                    <p className="weSentText">We sent it your mobile number</p>
                    <Row>
                      <Col span={12}>
                        <p className="weSentText">(+91) {phoneNumber}</p>
                      </Col>
                      <Col span={12}>
                        <p className="editIcon">
                          Edit <EditOutlined />
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        paddingTop: "30px",
                        paddingLeft: "15px",
                      }}
                    >
                      <Col span={24} style={{ textAlign: "center" }}>
                        <Row>
                          <Col span={4}>
                            <p className="otpInput">
                              {customerDetails.realDigits[0]}
                            </p>
                          </Col>
                          <Col span={4}>
                            <p className="otpInput">
                              {customerDetails.realDigits[1]}
                            </p>
                          </Col>
                          <Col span={4}>
                            <p className="otpInput">
                              {customerDetails.realDigits[2]}
                            </p>
                          </Col>
                          <Col span={4}>
                            <p className="otpInput">
                              {customerDetails.realDigits[3]}
                            </p>
                          </Col>
                          <Col span={4}>
                            <p className="otpInput">
                              {customerDetails.realDigits[4]}
                            </p>
                          </Col>
                          <Col span={4}>
                            <p className="otpInput">
                              {customerDetails.realDigits[5]}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="didntReceiveDiv">
                      <p className="didntReceiveText">
                        Didn't receive an OTP ?
                      </p>
                      <p className="resendOtpText">
                        <span className="resendOtpSpan">Resend OTP</span>/
                        Verify via call
                      </p>
                    </div>
                    <div className="nextBtnDivDesk">
                      <Button
                        size="large"
                        htmlType="submit"
                        className="verifyOtpBtnDesk"
                        onClick={onVerifyOTP}
                      >
                        Verify OTP
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col span={12} className="loginAnimColDesk">
            <img src={LoginGif} alt="" />
          </Col>
        </Row>
      </div>
      <div className="desktop-hide">
        <div className="loginMain">
          {customerDetails.onNext ? (
            <div>
              <img src={UserImg} alt="user icon" className="userIcon" />
              <div className="textDiv">
                <p className="enterText">
                  Enter your mobile number to start ordering
                </p>
                <p className="confirmationText">
                  We will send you a confirmation code
                </p>
                <Form name="inputForm" onFinish={handleLogin}>
                  <label className="inputLabel">Mobile</label>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your mobile number!",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      prefix={"(+91)"}
                      maxLength={10}
                      value={phoneNumber}
                      size="large"
                      className="mobileInput"
                      autoComplete="off"
                    />
                  </Form.Item>
                  <div className="nextBtnDiv">
                    <Form.Item>
                      <Button
                        size="large"
                        htmlType="submit"
                        className="nextBtn"
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          ) : (
            <div>
              <img src={UserImg} alt="user icon" className="userIcon" />
              <div className="otpTextDiv">
                <p className="enterCodeText">Enter code sent to your number</p>
                <p className="weSentText">We sent it your mobile number</p>
                <Row>
                  <Col span={12}>
                    <p className="weSentText">(+91) {phoneNumber}</p>
                  </Col>
                  <Col span={12}>
                    <p className="editIcon">
                      Edit <EditOutlined />
                    </p>
                  </Col>
                </Row>
                <Row
                  style={{
                    paddingTop: "30px",
                    paddingLeft: "15px",
                  }}
                >
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Row>
                      <Col span={4}>
                        <p className="otpInput">
                          {customerDetails.realDigits[0]}
                        </p>
                      </Col>
                      <Col span={4}>
                        <p className="otpInput">
                          {customerDetails.realDigits[1]}
                        </p>
                      </Col>
                      <Col span={4}>
                        <p className="otpInput">
                          {customerDetails.realDigits[2]}
                        </p>
                      </Col>
                      <Col span={4}>
                        <p className="otpInput">
                          {customerDetails.realDigits[3]}
                        </p>
                      </Col>
                      <Col span={4}>
                        <p className="otpInput">
                          {customerDetails.realDigits[4]}
                        </p>
                      </Col>
                      <Col span={4}>
                        <p className="otpInput">
                          {customerDetails.realDigits[5]}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="didntReceiveDiv">
                  <p className="didntReceiveText">Didn't receive an OTP ?</p>
                  <p className="resendOtpText">
                    <span className="resendOtpSpan">Resend OTP</span>/ Verify
                    via call
                  </p>
                </div>
                <div className="nextBtnDiv">
                  <Button
                    size="large"
                    htmlType="submit"
                    className="verifyOtpBtn"
                    onClick={onVerifyOTP}
                  >
                    Verify OTP
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default Login;
