import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form, message } from "antd";
import {
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import QueueAnim from "rc-queue-anim";
import Animate from "rc-animate";
import LeftArrow from "../../assets/images/leftArrow.svg";
import TrashBin from "../../assets/images/trash-bin.svg";
import Closegrey from "../../assets/images/close-grey.svg";
import DefaultImage from "../../assets/images/default-product-img.svg";
import RedHeart from "../../assets/images/heart-red.svg";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";
import _ from "lodash";

const Cart = () => {
  const history = useHistory();

  const [cart, setCart] = useState({
    lineItems: [],
    total: 0,
    tax: 0,
    totalQty: 0,
  });

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          lineItems: [],
          total: 0,
          tax: 0,
          totalQty: 0,
        };
    console.log("cartItems", cartItems);
    setCart(cartItems);
  }, []);

  const addProdToCart = (prod, qty) => {
    const weight = parseFloat(qty);
    const addedToCart = cart.lineItems || [];
    const index = addedToCart.findIndex(
      (p) => p.product_id === prod.product_id
    );
    let newObj =
      index >= 0
        ? {
            ...addedToCart[index],
            weight: addedToCart[index].weight + weight,
            add_on_group: addedToCart[index].add_on_group
              ? addedToCart[index].add_on_group
              : [],
          }
        : {
            product_id: prod.product_id,
            name: prod.name,
            prodCode: prod.sku,
            price: prod.listprice,
            total: 0,
            tax: 0,
            taxAmount: 0,
            taxRate: 0,
            weight: weight,
            add_on_group: prod.add_on_group ? prod.add_on_group : [],
          };
    const addOnsPriceSum = _.sumBy(newObj?.add_on_group, (item) =>
      Number(item.price)
    );
    const mrp = (addOnsPriceSum + parseFloat(newObj.price)) * newObj.weight;
    const tax = mrp - mrp / (1 + newObj.taxRate / 100);
    newObj.taxAmount = tax;
    newObj.total = mrp;

    if (index >= 0) {
      if (newObj.weight === 0) {
        addedToCart.splice(index, 1);
      } else {
        addedToCart.splice(index, 1, newObj);
      }
    } else {
      addedToCart.push(newObj);
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    for (let i = 0; i < addedToCart.length; i++) {
      totalPrice += Number(addedToCart[i].total);
      totalItemsQty += addedToCart[i].weight;
      totalTax += Number(addedToCart[i].taxAmount);
      addedToCart[i].key = i;
    }
    let newCart = {
      ...cart,
      lineItems: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      totalQty: totalItemsQty,
    };

    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    localStorage.setItem("itemsQuantity", JSON.stringify(totalItemsQty));
  };

  const handleNext = () => {
    if (cart.lineItems.length > 0) {
      history.push("/payments");
    } else {
      message.error("No Products in cart!");
    }
  };

  const handleBack = () => {
    history.push("/restaurant");
  };

  const setDefaultImage = (e) => {
    e.target.src = DefaultImage;
  };

  const deleteCart = () => {
    setCart({
      lineItems: [],
      total: 0,
      tax: 0,
      totalQty: 0,
    });
    localStorage.setItem(
      "cart",
      JSON.stringify({
        lineItems: [],
        total: 0,
        tax: 0,
        totalQty: 0,
      })
    );
    localStorage.setItem("itemsQuantity", JSON.stringify(0));
  };


  return (
    <div>
        <div className="mobile-hide">
          <MenuComponent />
        </div>
        <div className="mobile-hide">
        <Scrollbars autoHide className="prodCardsScrollDesk">
          <div className="cartDesk">
            <Row style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Row>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <img
                      src={LeftArrow}
                      alt=""
                      className="arrowLeft"
                      onClick={handleBack}
                    />
                  </Col>
                  <Col span={20}>
                    <p className="cartTitleDesk">Cart</p>
                  </Col>
                </Row>
              </Col>
              <Col
                span={12}
                style={{ textAlign: "right", alignSelf: "center" }}
              >
                <p
                  className="clearAll"
                  onClick={() =>
                    cart.lineItems.length > 0 ? deleteCart() : ""
                  }
                >
                  <img
                    src={TrashBin}
                    alt=""
                    // onClick={handleBack}
                    width="3%"
                  />{" "}
                  &nbsp; Clear All &nbsp;
                  <img
                    src={Closegrey}
                    alt=""
                    // onClick={handleBack}
                    width="2.5%"
                  />
                </p>
              </Col>
            </Row>
            <div className="cartItemsDiv">
              <div>
                {/* <Scrollbars autoHide className="itemsInCartScroll"> */}
                <div>
                  {Object.entries(
                    _.groupBy([...cart?.lineItems] || [], "restaurantInfo.name")
                  ).map(([key, value]) => (
                    <>
                      <div style={{ marginTop: 20, marginBottom: 30 }}>
                        <Card
                          bodyStyle={{ padding: 20 }}
                          className="restDetailsCardInCart"
                        >
                          <Row>
                            <Col span={12}>
                              <p className="restNameInCart">{key}</p>
                              <p className="restItemsInCart">
                                {value?.[0]?.restaurantInfo?.shortDescription ||
                                  ""}
                              </p>
                            </Col>
                            <Col span={12}>
                              <img
                                src={
                                  value?.[0]?.restaurantInfo?.storeImage || ""
                                }
                                alt=""
                                className="dishImage"
                              />
                            </Col>
                          </Row>
                        </Card>
                      </div>
                      {value?.map((cartItem, cartIndex) => (
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ marginBottom: 10 }}>
                            <Card
                              bodyStyle={{ padding: 10 }}
                              className="prodCardDesk"
                            >
                              <Row>
                                <Col span={3}>
                                  <Animate
                                    transitionName="fade"
                                    transitionAppear
                                  >
                                    <div key="1">
                                      <img
                                        src={cartItem.imageUrl}
                                        onError={setDefaultImage}
                                        alt=""
                                        className="prodImg"
                                      />
                                    </div>
                                  </Animate>
                                </Col>
                                <Col span={21} style={{ paddingLeft: 10 }}>
                                  <QueueAnim name="QueueAnim" delay={300}>
                                    <div key="a">
                                      <Row>
                                        <Col span={20}>
                                          <p className="prodName">
                                            {cartItem.name}
                                          </p>
                                        </Col>
                                        <Col span={4}>
                                          <img
                                            src={RedHeart}
                                            alt=""
                                            className="redHeart"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={24}>
                                          <p className="prodDesc">
                                            {cartItem.desc}
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row
                                        style={{
                                          paddingTop: 10,
                                          paddingBottom: 5,
                                        }}
                                      >
                                        <Col
                                          span={21}
                                          style={{ alignSelf: "center" }}
                                        >
                                          <p className="prodPrice">
                                            &#8377;{" "}
                                            {Number(cartItem.total).toFixed(2)}
                                          </p>
                                        </Col>
                                        <Col span={3}>
                                          {" "}
                                          <p style={{ marginBottom: 0 }}>
                                            <span className="minusBtnDesk">
                                              <MinusOutlined
                                                onClick={() =>
                                                  addProdToCart(cartItem, -1)
                                                }
                                              />
                                            </span>
                                            &ensp;
                                            {cartItem.weight}
                                            &ensp;
                                            <span className="plusBtnDesk">
                                              <PlusOutlined
                                                onClick={() =>
                                                  addProdToCart(cartItem, 1)
                                                }
                                              />
                                            </span>
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row
                                        style={{
                                          display:
                                            cartItem?.add_on_group.length > 0
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        <Col span={24}>
                                          <p className="addonsText">Add-ons</p>
                                        </Col>
                                      </Row>
                                      {cartItem?.add_on_group?.map(
                                        (addOnItem) => (
                                          <div>
                                            <Row>
                                              <Col span={8}>
                                                <ul className="addonsList">
                                                  <li className="addonsProd">
                                                    {addOnItem.name}
                                                  </li>
                                                </ul>
                                              </Col>
                                              <Col
                                                span={16}
                                                style={{ alignSelf: "center" }}
                                              >
                                                <p className="addonsProdPriceDesk">
                                                  &#8377; {addOnItem.price}
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </QueueAnim>
                                </Col>
                              </Row>
                            </Card>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
                {/* </Scrollbars> */}
              </div>
              {/* <div style={{ marginTop: 10 }}>
                <p className="billDetailsText">Bill Details</p>
              </div> */}
              <div style={{ marginTop: 30 }}>
                <Row style={{ padding: "0px 10px" }}>
                  <Col span={12}>
                    <p className="subTotalDesk">Subtotal</p>
                  </Col>
                  <Col span={12}>
                    <p className="subTotalValueDesk">
                      &#8377; {_.sumBy(cart.lineItems, "total").toFixed(2)}
                    </p>
                  </Col>
                </Row>
                {/* <Row style={{ padding: "0px 10px" }}>
                  <Col span={12}>
                    <p className="subTotal">Tax & Fees</p>
                  </Col>
                  <Col span={12}>
                    <p className="subTotalValue">
                      &#8377; {_.sumBy(cart.lineItems, "tax")}
                    </p>
                  </Col>
                </Row>
                <Row style={{ padding: "0px 10px" }}>
                  <Col span={12}>
                    <p className="totalLabel">Total</p>
                  </Col>
                  <Col span={12}>
                    <p className="totalValue">
                      &#8377; {_.sumBy(cart.lineItems, "total").toFixed(2)}
                    </p>
                  </Col>
                </Row> */}
              </div>
              <div className="nextBtnDivInCartDesk">
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    size="large"
                    htmlType="submit"
                    className="nextBtnInCart"
                    onClick={handleNext}
                  >
                    Proceed To Checkout
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="prodFooter">
          <FooterComponent />
          </div>
          </Scrollbars>
        </div>
        <div className="desktop-hide">
          <div className="cartMain">
            {/* <Scrollbars autoHide className="cartSroll"> */}
            <Row>
              <Col span={10}>
                <img
                  src={LeftArrow}
                  alt=""
                  className="arrowLeft"
                  onClick={handleBack}
                />
              </Col>
              <Col span={14}>
                <p className="cartTitle">Cart</p>
              </Col>
            </Row>
            <div>
              <Scrollbars autoHide className="itemsInCartScroll">
                <div>
                  {Object.entries(
                    _.groupBy([...cart?.lineItems] || [], "restaurantInfo.name")
                  ).map(([key, value]) => (
                    <>
                      <div style={{ marginTop: 20, marginBottom: 30 }}>
                        <Card
                          bodyStyle={{ padding: 20 }}
                          className="restDetailsCardInCart"
                        >
                          <Row>
                            <Col span={12}>
                              <p className="restNameInCart">{key}</p>
                              <p className="restItemsInCart">
                                {value?.[0]?.restaurantInfo?.shortDescription ||
                                  ""}
                              </p>
                            </Col>
                            <Col span={12}>
                              <img
                                src={
                                  value?.[0]?.restaurantInfo?.storeImage || ""
                                }
                                alt=""
                                className="dishImage"
                              />
                            </Col>
                          </Row>
                        </Card>
                      </div>
                      {value?.map((cartItem, cartIndex) => (
                        <div style={{ padding: "0px 10px" }}>
                          <div style={{ marginBottom: 10 }}>
                            {/* <Card bodyStyle={{ padding: 10 }} className="prodCard">
                          <Row>
                            <Col span={8}>
                              <Animate transitionName="fade" transitionAppear>
                                <div key="1">
                                  <img
                                    src={item.imageUrl}
                                    onError={setDefaultImage}
                                    alt=""
                                    className="prodImg"
                                  />
                                </div>
                              </Animate>
                            </Col>
                            <Col span={16} style={{ paddingLeft: 10 }}>
                              <QueueAnim name="QueueAnim" delay={300}>
                                <div key="a">
                                  <Row>
                                    <Col span={20}>
                                      <p className="prodName">{item.name}</p>
                                    </Col>
                                    <Col span={4}>
                                      <img
                                        src={RedHeart}
                                        alt=""
                                        className="redHeart"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={24}>
                                      <p className="prodDesc">
                                        {item.short_desc}
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row style={{ paddingTop: 10 }}>
                                    <Col
                                      span={15}
                                      style={{ alignSelf: "center" }}
                                    >
                                      <p className="prodPrice">
                                        &#8377; {item.listprice}
                                      </p>
                                    </Col>
                                    <Col span={9}>
                                      {cart?.lineItems?.length > 0 &&
                                      _.filter(
                                        [...cart.lineItems],
                                        (data) =>
                                          data.product_id === item.product_id &&
                                          data.weight > 0
                                      )?.length > 0 ? (
                                        <p className="IncDecBtns">
                                          <span className="minusBtn">
                                            <MinusOutlined
                                              disabled={
                                                _.filter(
                                                  [...cart.lineItems],
                                                  (data) =>
                                                    data.product_id ===
                                                      item.product_id &&
                                                    data.weight > 0
                                                )?.[0]?.weight === 0
                                              }
                                            />
                                          </span>
                                          &ensp;
                                          {
                                            _.filter(
                                              [...cart.lineItems],
                                              (data) =>
                                                data.product_id ===
                                                  item.product_id &&
                                                data.weight > 0
                                            )?.[0]?.weight
                                          }
                                          &ensp;
                                          <span className="plusBtn">
                                            <PlusOutlined
                                              onClick={
                                                item.add_on_group !== null &&
                                                _.filter(
                                                  [...cart.lineItems],
                                                  (data) =>
                                                    data.product_id ===
                                                      item.product_id &&
                                                    data.weight > 0
                                                )?.[0]?.weight > 0
                                                  ? () => {
                                                      setSelectedAddonObj(item);
                                                      setAddonsSelectionModal(
                                                        true
                                                      );
                                                    }
                                                  : () => addProdToCart(item, 1)
                                              }
                                            />
                                          </span>
                                        </p>
                                      ) : (
                                        <Button
                                          className="addButton"
                                          onClick={() =>
                                            item?.add_on_group !== null
                                              ? handleAddons(item)
                                              : addProdToCart(item, 1)
                                          }
                                        >
                                          {item?.add_on_group !== null
                                            ? "ADD +"
                                            : "ADD"}
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </QueueAnim>
                            </Col>
                          </Row>
                        </Card> */}
                            <Row>
                              <Col span={12}>
                                <p className="prodInCart">{cartItem.name}</p>
                              </Col>
                              <Col span={6} className="prodQty">
                                <p style={{ marginBottom: "5px" }}>
                                  <MinusOutlined
                                    style={{ paddingRight: 5 }}
                                    onClick={() => addProdToCart(cartItem, -1)}
                                  />{" "}
                                  <span className="prodQtyInCart">
                                    {cartItem.weight}
                                  </span>{" "}
                                  <PlusOutlined
                                    style={{ paddingLeft: 5 }}
                                    onClick={() => addProdToCart(cartItem, 1)}
                                  />
                                </p>
                              </Col>
                              <Col span={6}>
                                <p className="prodPriceInCart">
                                  &#8377; {Number(cartItem.total).toFixed(2)}
                                </p>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                display:
                                  cartItem?.add_on_group.length > 0
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <Col span={24}>
                                <p className="addonsText">Add-ons</p>
                              </Col>
                            </Row>
                            {cartItem?.add_on_group?.map((addOnItem) => (
                              <div>
                                <Row>
                                  <Col span={21}>
                                    <ul className="addonsList">
                                      <li className="addonsProd">
                                        {addOnItem.name}
                                      </li>
                                    </ul>
                                  </Col>
                                  <Col span={3}>
                                    <p className="addonsProdPrice">
                                      &#8377; {addOnItem.price}
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              </Scrollbars>
            </div>
            <div style={{ marginTop: 10 }}>
              <p className="billDetailsText">Bill Details</p>
            </div>
            <div>
              <Row style={{ padding: "0px 10px" }}>
                <Col span={12}>
                  <p className="subTotal">Subtotal</p>
                </Col>
                <Col span={12}>
                  <p className="subTotalValue">
                    &#8377; {_.sumBy(cart.lineItems, "total").toFixed(2)}
                  </p>
                </Col>
              </Row>
              <Row style={{ padding: "0px 10px" }}>
                <Col span={12}>
                  <p className="subTotal">Tax & Fees</p>
                </Col>
                <Col span={12}>
                  <p className="subTotalValue">
                    &#8377; {_.sumBy(cart.lineItems, "tax")}
                  </p>
                </Col>
              </Row>
              <Row style={{ padding: "0px 10px" }}>
                <Col span={12}>
                  <p className="totalLabel">Total</p>
                </Col>
                <Col span={12}>
                  <p className="totalValue">
                    &#8377; {_.sumBy(cart.lineItems, "total").toFixed(2)}
                  </p>
                </Col>
              </Row>
            </div>
            <div className="nextBtnDivInCart">
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  size="large"
                  htmlType="submit"
                  className="nextBtnInCart"
                  onClick={handleNext}
                >
                  Proceed To Checkout
                </Button>
              </Form.Item>
            </div>
            {/* </Scrollbars> */}
          </div>
        </div>
      <div className="desktop-hide">
        <MenuComponent />
      </div>
    </div>
  );
};

export default Cart;
