import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form, Input } from "antd";
import Axios from "axios";
import { fnbURL } from "../../../src/constants/config";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";

const Wallet = () => {
  const history = useHistory();
  const walletId = JSON.parse(localStorage.getItem("walletId"));
  const [walletAmount, setWalletAmount] = useState(0);
  const token = JSON.parse(localStorage.getItem("clientToken"));

  useEffect(() => {
    if(walletId !== null) {
      const getwalletBalance = {
        query: `query{
          getwalletBalance(wallet_Id:"${walletId}"){
              b2c_Customer_Id
              b2c_Customer_name
              balance_Amt
          }
      }`,
      };
      Axios({
        url: fnbURL,
        method: "POST",
        async: true,
        crossDomain: true,
        data: getwalletBalance,
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
          clientId: "39BC576048054849BFBFEDBF29142A3E",
        },
      }).then((response) => {
        console.log("response", response);
        const walletBal = response.data.data.getwalletBalance.balance_Amt;
        setWalletAmount(walletBal);
      })
    }
  }, [])

  const handleBack = () => {
    history.push("/restaurant");
  };

  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
      </div>
      <div className="mobile-hide">
        <Scrollbars autoHide className="prodCardsScrollDesk">
          <div className="cartDesk">
            <Row style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Row>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <img
                      src={LeftArrow}
                      alt=""
                      className="arrowLeft"
                      onClick={handleBack}
                    />
                  </Col>
                  <Col span={20}>
                    <p className="cartTitleDesk">My Wallet</p>
                  </Col>
                </Row>
              </Col>
            </Row>
						<div style={{ marginTop: 20 }}>
            <Card bodyStyle={{ padding: 10 }} className="wallet-card">
              <p className="available-balance">AVAILABLE BALANCE</p>
              <p className="wallet-bal">&#8377; {walletAmount}</p>
            </Card>
            <Card bodyStyle={{ padding: 15 }} className="topup-card">
              <p className="topup-wallet">Topup Wallet</p>
              <Input
                type="number"
                placeholder="&#8377; Enter Amount"
                className="topup-input"
              />
              <p className="recommended">Recommended</p>
              <Row gutter={[8,8]}>
                <Col span={6}>
                  <Card bodyStyle={{ padding: 0 }} className="topup-activecard">
                    <p className="topup-value-active">&#8377; 100</p>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card bodyStyle={{ padding: 0 }} className="topup-value-card">
                    <p className="topup-value">&#8377; 500</p>
                  </Card>
                </Col>
                <Col span={6}>
								<Card bodyStyle={{ padding: 0 }} className="topup-value-card">
                    <p className="topup-value">&#8377; 1000</p>
                  </Card>
								</Col>
                <Col span={6}>
								<Card bodyStyle={{ padding: 0 }} className="topup-value-card">
                    <p className="topup-value">&#8377; 2000</p>
                  </Card>
								</Col>
              </Row>
            </Card>
          </div>
          </div>
          <div className="prodFooter">
            <FooterComponent />
          </div>
        </Scrollbars>
      </div>
      <div className="desktop-hide">
        <div className="cartMain">
          <Row>
            <Col span={8}>
              <img
                src={LeftArrow}
                alt=""
                className="arrowLeft"
                onClick={handleBack}
              />
            </Col>
            <Col span={16}>
              <p className="cartTitle">My Wallet</p>
            </Col>
          </Row>
          <div style={{ marginTop: 20 }}>
            <Card bodyStyle={{ padding: 10 }} className="wallet-card">
              <p className="available-balance">AVAILABLE BALANCE</p>
              <p className="wallet-bal">&#8377; {walletAmount}</p>
            </Card>
            <Card bodyStyle={{ padding: 15 }} className="topup-card">
              <p className="topup-wallet">Topup Wallet</p>
              <Input
                type="number"
                placeholder="&#8377; Enter Amount"
                className="topup-input"
              />
              <p className="recommended">Recommended</p>
              <Row gutter={[8,8]}>
                <Col span={6}>
                  <Card bodyStyle={{ padding: 0 }} className="topup-activecard">
                    <p className="topup-value-active">&#8377; 100</p>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card bodyStyle={{ padding: 0 }} className="topup-value-card">
                    <p className="topup-value">&#8377; 500</p>
                  </Card>
                </Col>
                <Col span={6}>
								<Card bodyStyle={{ padding: 0 }} className="topup-value-card">
                    <p className="topup-value">&#8377; 1000</p>
                  </Card>
								</Col>
                <Col span={6}>
								<Card bodyStyle={{ padding: 0 }} className="topup-value-card">
                    <p className="topup-value">&#8377; 2000</p>
                  </Card>
								</Col>
              </Row>
            </Card>
          </div>
          <div className="topUpBtnDiv">
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  size="large"
                  htmlType="submit"
                  className="topUpBtn"
                >
                  Topup
                </Button>
              </Form.Item>
            </div>
        </div>
      </div>
      <div className="desktop-hide">
        <MenuComponent />
      </div>
    </div>
  );
};

export default Wallet;
