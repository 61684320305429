import React, { useEffect } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import { firebaseConfig } from "./constants/firebase-config";
import Spaces from "../src/components/spaces";
import Login from "../src/components/login";
import SignUp from "../src/components/signUp";
import Home from "../src/components/home";
import Restaurant from "../src/components/restaurant";
import Cart from "../src/components/cart";
import Payments from "../src/components/payments";
import RateOrder from "../src/components/rateOrder";
import ThanksPage from "../src/components/thanksPage";
import OrderHistory from "../src/components/orderHistory";
import AddonsPage from "../src/components/addOnsPage";
import Favourites from "../src/components/favourites";
import MoreOrderHistory from "../src/components/moreOrderHistory";
import AccountPage from "../src/components/account";
import WalletPage from "../src/components/wallet";
import LoyaltyPage from "../src/components/loyalty";

const fireBaseApp = initializeApp(firebaseConfig);
getAnalytics(fireBaseApp);

const App = () => {

  useEffect(() => {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: "BGxNv1YMcohiiE8ALpbnxutkWrB8sevf5HPGLOxBp3j7x59gdgxAjKXi35-dh8UKueqTONMgV_x_j1GDgcd04Nw" })
      .then((currentToken) => {
        if (currentToken) {
          console.log("Firebase Token:",currentToken);
        } else {
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  });

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Spaces} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/restaurant" component={Restaurant} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/payments" component={Payments} />
        <Route exact path="/rate-order" component={RateOrder} />
        <Route exact path="/thanks" component={ThanksPage} />
        <Route exact path="/order-history" component={OrderHistory} />
        <Route exact path="/add-ons" component={AddonsPage} />
        <Route exact path="/favourites" component={Favourites} />
        <Route exact path="/more-order-history" component={MoreOrderHistory} />
        <Route exact path="/account" component={AccountPage} />
        <Route exact path="/wallet" component={WalletPage} />
        <Route exact path="/loyalty" component={LoyaltyPage} />
      </Switch>
    </Router>
  );
};

export default App;
