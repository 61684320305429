import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Skeleton } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import Axios from "axios";
import { fnbURL } from "../../../src/constants/config";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";
import _, { reverse } from "lodash";

const OrderHistory = () => {
  const history = useHistory();
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const walletId = JSON.parse(localStorage.getItem("walletId"));
  const token = JSON.parse(localStorage.getItem("clientToken"));
  const [walletTopupList, setWalletTopupList] = useState([]);

  useEffect(() => {
    setLoading(true);
    const customerId = JSON.parse(localStorage.getItem("customerId"))
      ? JSON.parse(localStorage.getItem("customerId"))
      : null;
    const getOrders = {
      query: `query {
        getMyOrders(customerId: "${customerId}") {
            cWCOrderId
            orderNo
            dateCreated
            status
            paymentMethod
            storeName
            grossAmount
            lineItems
                {
                    productCode
                    name
                    quantity
                    price
                    total
                    addOnProducts{
                        id
                        name
                        price
                    }
                }
              }
    }`,
    };
    Axios({
      url: fnbURL,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getOrders,
      headers: {
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      console.log("response", response);
      if (response.status === 200) {
        const responseData = response.data.data.getMyOrders;
        let newResponseData = reverse(responseData);
        setOrderHistoryData([...newResponseData]);
        setLoading(false);
      }
    });

    if(walletId !== null) {
    const walletTransactions = {
      query: `query{
        getWalletTransactions(wallet_Id:"${walletId}"){
            wallet_trx_id
            date
            trx_type
            trx_value
            opening_amt
            closing_amt
        }
    }`,
    };
    Axios({
      url: fnbURL,
      method: "POST",
      async: true,
      crossDomain: true,
      data: walletTransactions,
      headers: {
        "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
          clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      console.log('response', response);
      const responseData = response.data.data.getWalletTransactions;
        let newResponseData = reverse(responseData);
        setWalletTopupList([...newResponseData]);
        setLoading(false);
    })
  }
  }, []);

  const backToHome = () => {
    history.push("/home");
  };

  const feedBack = () => {
    history.push("/rate-order");
  };

  const handleMore = (data) => {
    setLoading(true);
    const orderIndex = _.findIndex(
      orderHistoryData,
      (item) => item.cwcOrderId === data.cwcOrderId
    );
    if (orderIndex !== -1) {
      localStorage.setItem("selectedOrder", JSON.stringify(data));
      history.push("/more-order-history");
    }
  };

  const [selectType, setSelectType] = useState("orders");

  const handleSelection = (fieldName) => {
    setSelectType(fieldName);
  };



  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
      </div>
      <div className="mobile-hide">
        <Scrollbars autoHide className="prodCardsScrollDesk">
          <div className="orderHistoryPageDesk">
            <Row style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Row>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <img
                      src={LeftArrow}
                      alt=""
                      className="arrowLeft"
                      onClick={backToHome}
                    />
                  </Col>
                  <Col span={20}>
                    <p className="cartTitleDesk">Order History</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div style={{ marginTop: 10 }}>
              {/* <Scrollbars autoHide className="orderHistoryScroll"> */}
              {orderHistoryData.map((data, index) => (
                <div style={{ padding: 10 }}>
                  <Card
                    bodyStyle={{ padding: 15 }}
                    className="orderDetailsCard"
                  >
                    <Row>
                      <Col span={12}>
                        <p className="OrderRestName">{data.storeName}</p>
                      </Col>
                      <Col span={12}>
                        <p className="OrderDate">{data.dateCreated}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={4}>
                        <p className="OrderProdName">
                          {data.lineItems.length > 0
                            ? data.lineItems[0].name
                            : ""}
                        </p>
                      </Col>
                      <Col span={3}>
                        <p className="OrderProdQty">
                          {data.lineItems.length > 0
                            ? "X" + data.lineItems[0].quantity
                            : ""}
                        </p>
                      </Col>
                      <Col span={17}>
                        <p className="OrderProdPrice">
                          &#8377; {data.grossAmount.toFixed(2)}
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display:
                          data.lineItems[0].addOnProducts.length > 0
                            ? "block"
                            : "none",
                      }}
                    >
                      <Col span={24}>
                        <ul className="addonsList">
                          <li className="addonsProd">
                            {data.lineItems[0].addOnProducts.length > 0
                              ? data.lineItems[0].addOnProducts[0].name
                              : ""}
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <p className="more" style={{ marginTop: 10 }}>
                      More Details{" "}
                      <CaretDownOutlined
                        style={{ verticalAlign: "middle" }}
                        onClick={() => handleMore(data)}
                      />
                    </p>
                    <Row gutter={[8, 0]}>
                      <Col span={5}>
                        <Button className="reOrderBtn" size="large">
                          REORDER
                        </Button>
                      </Col>
                      <Col span={5}>
                        <Button
                          className="rateOrderBtn"
                          size="large"
                          onClick={feedBack}
                        >
                          RATE ORDER
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </div>
              ))}
              {/* </Scrollbars> */}
            </div>
            {/* <div className="nextBtnDivInOrderhistory" style={{ marginTop: 10 }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                size="large"
                htmlType="submit"
                className="nextBtnInOrderHistory"
                onClick={backToHome}
              >
                Next
              </Button>
            </Form.Item>
          </div> */}
          </div>
          <div className="prodFooter">
            <FooterComponent />
          </div>
        </Scrollbars>
      </div>
      <div className="desktop-hide">
        <Scrollbars autoHide className="orderHistoryScroll">
          <div className="cartMain">
            <Skeleton
              loading={loading}
              style={{ padding: "0px 15px" }}
              paragraph={{ rows: 4 }}
            />
            <Skeleton
              loading={loading}
              style={{ padding: "0px 15px" }}
              paragraph={{ rows: 4 }}
            />
            <Skeleton
              loading={loading}
              style={{ padding: "0px 15px" }}
              paragraph={{ rows: 4 }}
            >
              <Row>
                <Col span={8}>
                  <img
                    src={LeftArrow}
                    alt=""
                    className="arrowLeft"
                    onClick={backToHome}
                  />
                </Col>
                <Col span={12}>
                  <p className="cartTitle">Order History</p>
                </Col>
              </Row>
              <Row gutter={[8, 8]} style={{ marginTop: 20 }}>
                <Col span={12}>
                  <Card
                    className={selectType === "orders" ? "active-select" : "normal-select"}
                    bodyStyle={{ padding: 10 }}
                    onClick={() => handleSelection("orders")}
                  >
                    <p className={selectType === "orders" ? "active-card-order" : "normal-card-order"}>ORDERS</p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    className={selectType === "topups" ? "active-select" : "normal-select"}
                    bodyStyle={{ padding: 10 }}
                    onClick={() => handleSelection("topups")}
                  >
                    <p className={selectType === "topups" ? "active-card-order" : "normal-card-order"}>TOPUPS</p>
                  </Card>
                </Col>
              </Row>
              <div>
                {selectType === "topups" && (
                  <div style={{ marginTop: 10 }}>
                    <p className="trans-history">Transaction History</p>
                    {walletTopupList.map((item, index) => (
                    <Card
                      bodyStyle={{ padding: "10px 15px" }}
                      className="topup-trans-card"
                    >
                      <Row>
                        <Col span={8}>
                          <p className="top-up">Top-up</p>
                        </Col>
                        <Col span={16} style={{alignSelf: "center"}}>
                          <p className="top-up-date">{item.date}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <p className="top-up-type">{item.trx_type}</p>
                        </Col>
                        <Col span={12}>
                          <p className="top-up-amt">&#8377; {item.closing_amt}</p>
                        </Col>
                      </Row>
                    </Card>
                    ))}
                  </div>
                )}
                {selectType === "orders" && (
                  <div style={{ marginTop: 10 }}>
                    {/* <Scrollbars autoHide className="orderHistoryScroll"> */}
                    {orderHistoryData.map((data, index) => (
                      <div style={{ padding: 10 }}>
                        <Card
                          bodyStyle={{ padding: 15 }}
                          className="orderDetailsCard"
                        >
                          <Row>
                            <Col span={12}>
                              <p className="OrderRestName">{data.storeName}</p>
                            </Col>
                            <Col span={12}>
                              <p className="OrderDate">{data.dateCreated}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={15}>
                              <p className="OrderProdName">
                                {data.lineItems.length > 0
                                  ? data.lineItems[0].name
                                  : ""}
                              </p>
                            </Col>
                            <Col span={3}>
                              <p className="OrderProdQty">
                                {data.lineItems.length > 0
                                  ? "X" + data.lineItems[0].quantity
                                  : ""}
                              </p>
                            </Col>
                            <Col span={6}>
                              <p className="OrderProdPrice">
                                &#8377; {data.grossAmount.toFixed(2)}
                              </p>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              display:
                                data.lineItems[0].addOnProducts.length > 0
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <Col span={24}>
                              <ul className="addonsList">
                                <li className="addonsProd">
                                  {data.lineItems[0].addOnProducts.length > 0
                                    ? data.lineItems[0].addOnProducts[0].name
                                    : ""}
                                </li>
                              </ul>
                            </Col>
                          </Row>
                          <p className="more">
                            More{" "}
                            <CaretDownOutlined
                              style={{ verticalAlign: "middle" }}
                              onClick={() => handleMore(data)}
                            />
                          </p>
                          <Row gutter={[8, 0]}>
                            <Col span={12}>
                              <Button className="reOrderBtn" size="large">
                                REORDER
                              </Button>
                            </Col>
                            <Col span={12}>
                              <Button
                                className="rateOrderBtn"
                                size="large"
                                onClick={feedBack}
                              >
                                RATE ORDER
                              </Button>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    ))}
                    {/* </Scrollbars> */}
                  </div>
                )}
              </div>
              {/* {orderHistoryData.length > 0 ? (
                <div
                  className="nextBtnDivInOrderhistory"
                  style={{ marginTop: 10 }}
                >
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      size="large"
                      htmlType="submit"
                      className="nextBtnInOrderHistory"
                      onClick={backToHome}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </div>
              ) : null} */}
            </Skeleton>
          </div>
        </Scrollbars>
        <MenuComponent />
      </div>
    </div>
  );
};

export default OrderHistory;
