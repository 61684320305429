import React, { useState } from "react";
import { Row, Col, Drawer, Badge } from "antd";
import Animate from "rc-animate";
import { useHistory, useLocation } from "react-router-dom";
import HomeIcon from "../../assets/images/homeIcon.svg";
import BasketIcon from "../../assets/images/basketIcon.svg";
import MenuIcon from "../../assets/images/menuIcon.svg";
import ArrowLeft from "../../assets/images/arrow-left.svg";
import UserIconGrey from "../../assets/images/account-circle-grey.svg";
import HomeBlack from "../../assets/images/home-black.svg";
import HomeLine from "../../assets/images/home-line.svg";
import TasksLine from "../../assets/images/tasks-line.svg";
import TasksBlue from "../../assets/images/tasks-blue.svg";
import WalletLine from "../../assets/images/wallet-line.svg";
import WalletBlue from "../../assets/images/wallet-blue.svg";
import BellLine from "../../assets/images/bell-line.svg";
import BellBlue from "../../assets/images/notifications-blue.svg";
import UserLine from "../../assets/images/user-line.svg";
import UserBlue from "../../assets/images/user-blue.svg";
import HeartLine from "../../assets/images/heart-line.svg";
import HeartBlue from "../../assets/images/heart-blue.svg";
import LogoutLine from "../../assets/images/logout-line.svg";
import LogoutBlue from "../../assets/images/logout-blue.svg";
import HomeGrey from "../../assets/images/home-grey.svg";
import BasketBlue from "../../assets/images/basket-blue.svg";
import CWSuitLogo from "../../assets/images/cwsuite.png";
import UserIcon from "../../assets/images/account-circle.svg";
import ArrowLeftWhite from "../../assets/images/arrow-white.svg";
import MenuBlack from "../../assets/images/menu-big.svg";
import CartIcon from "../../assets/images/basket-big.svg";
import LoyaltyLine from "../../assets/images/loyalty.svg";
import LoyaltyBlue from "../../assets/images/loyalty-blue.svg";

const MenuComponent = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userName = userInfo.firstName + " " + userInfo.lastName;

  const history = useHistory();
  const locaation = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const showSideMenu = () => {
    setShowMenu(true);
  };

  const sideMenuClose = () => {
    setShowMenu(false);
  };

  const handleHome = () => {
    history.push("/home");
  };

  const handleOrderHistory = () => {
    history.push("/order-history");
  };

  const handleAccount = () => {
    history.push("/account");
  };

  const handleFavourites = () => {
    history.push("/favourites");
  };

  const handleLogout = () => {
    localStorage.removeItem("clientToken");
    history.push("/");
  };

  const handleCart = () => {
    history.push("/cart");
  };

  const handleWallet = () => {
    history.push("/wallet");
  };

  const handleLoyalty = () => {
    history.push("/loyalty");
  }

  const [selectedMenu, setSelectedMenu] = useState("Home");

  const menuItemsList = [
    {
      title: "Home",
      image: HomeBlack,
      selectedImage: HomeLine,
    },
    {
      title: "Order History",
      image: TasksLine,
      selectedImage: TasksBlue,
    },
    {
      title: "Wallet",
      image: WalletLine,
      selectedImage: WalletBlue,
    },
    {
      title: "Loyalty",
      image: LoyaltyLine,
      selectedImage: LoyaltyBlue,
    },
    {
      title: "Notifications",
      image: BellLine,
      selectedImage: BellBlue,
    },
    {
      title: "Account",
      image: UserLine,
      selectedImage: UserBlue,
    },
    {
      title: "Favorites",
      image: HeartLine,
      selectedImage: HeartBlue,
    },
    {
      title: "Logout",
      image: LogoutLine,
      selectedImage: LogoutBlue,
    },
  ];

  const handleSelectedMenu = (value) => {
    setSelectedMenu(value);
  };

  const [sideMenuDrawervisible, setSideMenuDrawervisible] = useState(false);
  const openSideMenu = () => {
    setSideMenuDrawervisible(true);
  };
  const onClose = () => {
    setSideMenuDrawervisible(false);
  };

  return (
    <div>
      <div className="mobile-hide">
        <Row className="headerRow">
          <Col span={2} style={{ alignSelf: "center" }}>
            <img src={MenuBlack} alt="" onClick={openSideMenu} />
          </Col>
          <Col span={4}>
            <img src={CWSuitLogo} alt="" width="50%" onClick={handleHome} />
          </Col>
          <Col span={15} />
          <Col span={2} style={{ alignSelf: "center", textAlign: "right" }}>
            <Badge count={JSON.parse(localStorage.getItem("itemsQuantity"))}>
              <img src={CartIcon} alt="" onClick={handleCart} />
            </Badge>
          </Col>
          <Col span={1} style={{ alignSelf: "center" }}>
            <img
              src={UserIcon}
              alt=""
              // className="userImage"
              onClick={handleAccount}
              style={{ float: "right" }}
            />
          </Col>
        </Row>
        <Drawer
          title={
            <div>
              <Row>
                <Col span={4}>
                  <img src={ArrowLeftWhite} alt="Status" onClick={onClose} />
                </Col>
                <Col span={20}>
                  <p className="spacesTextInMenuDesk">SPACES</p>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <img
                    src={UserIconGrey}
                    alt="Status"
                    className="userImageIcon"
                    // onClick={this.onCartArrow}
                  />
                </Col>
                <Col span={20}>
                  <p className="userNameInMenuDesk">{userName}</p>
                </Col>
              </Row>
            </div>
          }
          placement="left"
          closable={false}
          visible={sideMenuDrawervisible}
        >
          {menuItemsList.map((item) => (
            <Row
              onClick={
                item.title === "Home"
                  ? handleHome
                  : item.title === "Order History"
                  ? handleOrderHistory
                  : item.title === "Account"
                  ? handleAccount
                  : item.title === "Favorites"
                  ? handleFavourites
                  : item.title === "Wallet"
                  ? handleWallet
                  : item.title === "Loyalty"
                  ? handleLoyalty
                  : item.title === "Logout"
                  ? handleLogout
                  : ""
              }
              className={item.title === "Logout" ? "logoutMenu" : ""}
            >
              <Col span={1}>
                <div
                  className="selectedItem"
                  style={{
                    visibility:
                      selectedMenu === item.title ? "visible" : "hidden",
                  }}
                ></div>
              </Col>
              <Col
                span={23}
                style={{ paddingLeft: 7, paddingTop: 7, cursor: "pointer" }}
                onClick={() => handleSelectedMenu(item.title)}
              >
                <img
                  src={
                    selectedMenu === item.title
                      ? item.selectedImage
                      : item.image
                  }
                  alt=""
                  // className="menuIcons"
                />
                <span
                  className={
                    selectedMenu === item.title ? "menuTextActive" : "menuText"
                  }
                >
                  {item.title}
                </span>
              </Col>
            </Row>
          ))}
        </Drawer>
      </div>
      <div className="desktop-hide">
        <Row className="menuRow">
          <Col span={8}>
            <Animate transitionName="fade" transitionAppear>
              <div key="1">
                <img
                  src={locaation.pathname === "/cart" ? HomeGrey : HomeIcon}
                  alt=""
                  onClick={handleHome}
                />
              </div>
            </Animate>
          </Col>
          <Col span={8} style={{ textAlign: "center" }} onClick={handleCart}>
            <Animate transitionName="fade" transitionAppear>
              <div key="1">
                <Badge
                  count={JSON.parse(localStorage.getItem("itemsQuantity"))}
                >
                  <img
                    src={
                      locaation.pathname === "/cart" ? BasketBlue : BasketIcon
                    }
                    alt=""
                  />
                </Badge>
              </div>
            </Animate>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Animate transitionName="fade" transitionAppear>
              <div key="1">
                <img src={MenuIcon} alt="" onClick={showSideMenu} />
              </div>
            </Animate>
          </Col>
        </Row>
        <Drawer
          title={null}
          width="100%"
          placement="left"
          closable={false}
          visible={showMenu}
          bodyStyle={{ padding: 0 }}
        >
          <div className="drawerMainDiv">
            <Row>
              <Col span={4}>
                <img
                  src={ArrowLeft}
                  alt="Status"
                  style={{
                    paddingTop: "12px",
                  }}
                  onClick={sideMenuClose}
                />
              </Col>
              <Col span={20}>
                <p className="spacesTextInMenu">SPACES</p>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <img
                  src={UserIconGrey}
                  alt="Status"
                  className="userImageIcon"
                  // onClick={this.onCartArrow}
                />
              </Col>
              <Col span={20}>
                <p className="userNameInMenu">{userName}</p>
              </Col>
            </Row>
          </div>
          {menuItemsList.map((item) => (
            <Row
              onClick={
                item.title === "Home"
                  ? handleHome
                  : item.title === "Order History"
                  ? handleOrderHistory
                  : item.title === "Account"
                  ? handleAccount
                  : item.title === "Favorites"
                  ? handleFavourites
                  : item.title === "Wallet"
                  ? handleWallet
                  : item.title === "Loyalty"
                  ? handleLoyalty
                  : item.title === "Logout"
                  ? handleLogout
                  : ""
              }
              style={{
                marginTop: item.title === "Logout" ? "13rem" : "",
              }}
            >
              <Col span={1}>
                <div
                  className="selectedItem"
                  style={{
                    visibility:
                      selectedMenu === item.title ? "visible" : "hidden",
                  }}
                ></div>
              </Col>
              <Col
                span={23}
                style={{ paddingLeft: 7, paddingTop: 7 }}
                onClick={() => handleSelectedMenu(item.title)}
              >
                <img
                  src={
                    selectedMenu === item.title
                      ? item.selectedImage
                      : item.image
                  }
                  alt=""
                  className="menuIcons"
                />
                <span
                  className={
                    selectedMenu === item.title ? "menuTextActive" : "menuText"
                  }
                >
                  {item.title}
                </span>
              </Col>
            </Row>
          ))}
        </Drawer>
      </div>
    </div>
  );
};

export default MenuComponent;
