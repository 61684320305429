import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card } from "antd";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import TickIcon from "../../assets/images/tickIcon.svg";
import moment from "moment";
import "antd/dist/antd.css";
import "../styles.css";
import "../globalStyles.css";
// import MenuComponent from "../menuComponent";

const ThanksPage = () => {
  const history = useHistory();
  const [cartDetails, setCartDetails] = useState([]);
  const [restaurant, setRestaurant] = useState({});

  useEffect(() => {
    const cartList = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          lineItems: [],
          total: 0,
          tax: 0,
          totalQty: 0,
        };
    const storeInfo = JSON.parse(localStorage.getItem("storeInfo"));
    setRestaurant(storeInfo);
    setCartDetails(cartList);
  }, []);

  const handleHome = () => {
    history.push("/home");
    localStorage.setItem(
      "cart",
      JSON.stringify({
        lineItems: [],
        total: 0,
        tax: 0,
        totalQty: 0,
      })
    );
    localStorage.setItem("itemsQuantity", 0);
    localStorage.removeItem("cwcOrderId");
  };

  const orderId = window.localStorage.getItem("cwcOrderId");
  const orderdate1 = window.localStorage.getItem("orderDate");
  const onlyDate = moment(orderdate1).format("DD-MM-YYYY");
  const onlyTime = moment(orderdate1).format("HH:mm A");
  const QRvalue = `morder-${orderId}`;

  return (
    <div>
      <div className="mobile-hide">
        <div className="thanksPageDesk">
          <div className="thanksMainDiv">
            <div>
              <Card bodyStyle={{ padding: 30 }} className="thankCard">
                <img src={TickIcon} alt="" className="tickImage" />
                <p className="orderPlacedText">Your order has been placed</p>
              </Card>
              <p className="thankYouText">Thank you!</p>
              <p className="totalItems">
                {cartDetails.totalQty} Items - Rs.{cartDetails.total}
              </p>
              <div style={{ padding: "0px 10px" }}>
                <Row>
                  <Col span={12}>
                    <p className="paymentDetails">Payment Option</p>
                  </Col>
                  <Col span={12}>
                    <p className="paymentDetailsValues">Credit Card</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <p className="paymentDetails">Date</p>
                  </Col>
                  <Col span={12}>
                    <p className="paymentDetailsValues">
                      {onlyDate} at {onlyTime}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <p className="paymentDetails">Shop</p>
                  </Col>
                  <Col span={12}>
                    <p className="paymentDetailsValues">{restaurant.name}</p>
                  </Col>
                </Row>
              </div>
              <div style={{ textAlign: "center" }}>
                <p className="paymentConfirmation">Your payment confirmation</p>
                <QRCode
                  size={128}
                  value={QRvalue}
                  imageSettings={{
                    x: null,
                    y: null,
                    height: 24,
                    width: 24,
                    excavate: true,
                  }}
                />
                <p className="yourReceipt">Your receipt has been stored.</p>
              </div>
            </div>
            <div className="nextBtnDivInCart">
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  size="large"
                  htmlType="submit"
                  className="backToHomeBtn"
                  onClick={handleHome}
                >
                  Back To Home
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <div className="desktop-hide">
        <div className="cartMain">
          <div style={{ marginTop: 25 }}>
            <Card bodyStyle={{ padding: 30 }} className="thankCard">
              <img src={TickIcon} alt="" className="tickImage" />
              <p className="orderPlacedText">Your order has been placed</p>
            </Card>
            <p className="thankYouText">Thank you!</p>
            <p className="totalItems">
              {cartDetails.totalQty} Items - Rs.{cartDetails.total}
            </p>
            <div style={{ padding: "0px 10px" }}>
              <Row>
                <Col span={12}>
                  <p className="paymentDetails">Payment Option</p>
                </Col>
                <Col span={12}>
                  <p className="paymentDetailsValues">Credit Card</p>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <p className="paymentDetails">Date</p>
                </Col>
                <Col span={12}>
                  <p className="paymentDetailsValues">
                    {onlyDate} at {onlyTime}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <p className="paymentDetails">Shop</p>
                </Col>
                <Col span={12}>
                  <p className="paymentDetailsValues">{restaurant.name}</p>
                </Col>
              </Row>
            </div>
            <div style={{ textAlign: "center" }}>
              <p className="paymentConfirmation">Your payment confirmation</p>
              <QRCode
                size={128}
                value={QRvalue}
                imageSettings={{
                  x: null,
                  y: null,
                  height: 24,
                  width: 24,
                  excavate: true,
                }}
              />
              <p className="yourReceipt">Your receipt has been stored.</p>
            </div>
          </div>
          <div className="nextBtnDivInCart">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                size="large"
                htmlType="submit"
                className="backToHomeBtn"
                onClick={handleHome}
              >
                Back To Home
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
      {/* <MenuComponent /> */}
    </div>
  );
};

export default ThanksPage;
