import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card } from "antd";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
import moment from "moment";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";

const MoreOrderHistory = () => {
  const history = useHistory();
  const [restaurant, setRestaurant] = useState({});
  const [selectedOrder, setSelectedOrder] = useState({});

  useEffect(() => {
    const storeInfo = JSON.parse(localStorage.getItem("storeInfo"));
    const orderDetails = JSON.parse(localStorage.getItem("selectedOrder"));
    setSelectedOrder(orderDetails);
    setRestaurant(storeInfo);
  }, []);

  const handleBack = () => {
    history.push("/order-history");
  };

  const feedBack = () => {
    history.push("/rate-order");
  };

  const onlyDate = moment(selectedOrder.dateCreated).format("DD-MM-YYYY");
  const onlyTime = moment(selectedOrder.dateCreated).format("HH:mm A");
  const QRvalue = `morder-${selectedOrder.cwcOrderId}`;

  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
      </div>
      <div className="mobile-hide">
        <Scrollbars autoHide className="prodCardsScrollDesk">
          <div className="orderHistoryPageDesk">
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <img
                      src={LeftArrow}
                      alt=""
                      className="arrowLeft"
                      onClick={handleBack}
                    />
                  </Col>
                  <Col span={20}>
                    <p className="cartTitleDesk">Order History</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="orderHistDesk">
              <div style={{ padding: "0px 10px", marginBottom: 10 }}>
                <Row>
                  <Col span={8} />
                  <Col span={4}>
                    <p className="paymentDetails">Payment Option</p>
                  </Col>
                  <Col span={4}>
                    <p className="paymentDetailsValues">Credit Card</p>
                  </Col>
                  <Col span={8} />
                </Row>
                <Row>
                  <Col span={8} />
                  <Col span={4}>
                    <p className="paymentDetails">Date</p>
                  </Col>
                  <Col span={4}>
                    <p className="paymentDetailsValues">
                      {onlyDate} at {onlyTime}
                    </p>
                  </Col>
                  <Col span={8} />
                </Row>
                <Row>
                  <Col span={8} />
                  <Col span={4}>
                    <p className="paymentDetails">Shop</p>
                  </Col>
                  <Col span={4}>
                    <p className="paymentDetailsValues">{restaurant.name}</p>
                  </Col>
                  <Col span={8} />
                </Row>
              </div>
              <div style={{ textAlign: "center", marginBottom: 30 }}>
                <p className="paymentConfirmation">Your payment confirmation</p>
                <QRCode
                  size={128}
                  value={QRvalue}
                  imageSettings={{
                    x: null,
                    y: null,
                    height: 24,
                    width: 24,
                    excavate: true,
                  }}
                />
                <p className="yourReceipt">Your receipt has been stored.</p>
              </div>
              <Card
                bodyStyle={{ padding: 15 }}
                className="orderDetailsCardDesk"
              >
                <Row>
                  <Col span={12}>
                    <p className="OrderRestName">{selectedOrder.storeName}</p>
                  </Col>
                  <Col span={12}>
                    <p className="OrderDate">{selectedOrder.dateCreated}</p>
                  </Col>
                </Row>
                {/* <Scrollbars autoHide className="orderDetailsScroll"> */}
                {selectedOrder &&
                  selectedOrder?.lineItems?.map((item, index) => (
                    <div>
                      <Row>
                        <Col span={8}>
                          <p className="OrderProdName">{item.name}</p>
                        </Col>
                        <Col span={10}>
                          <p className="OrderProdQty">X {item.quantity}</p>
                        </Col>
                        <Col span={6}>
                          <p className="OrderProdPrice">&#8377; {item.price}</p>
                        </Col>
                      </Row>
                      {item?.addOnProducts?.map((addOnItem, addOnIndex) => (
                        <Row
                          style={{
                            display:
                              item?.addOnProducts?.length > 0
                                ? "block"
                                : "none",
                          }}
                        >
                          <Col span={24}>
                            <ul className="addonsList">
                              <li className="addonsProd">{addOnItem.name}</li>
                            </ul>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  ))}
                {/* </Scrollbars> */}
                <Row gutter={[8, 0]} style={{ marginTop: 10 }}>
                  <Col span={5}>
                    <Button className="reOrderBtn" size="large">
                      REORDER
                    </Button>
                  </Col>
                  <Col span={5}>
                    <Button
                      className="rateOrderBtn"
                      size="large"
                      onClick={feedBack}
                    >
                      RATE ORDER
                    </Button>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>
          <div className="prodFooter">
          <FooterComponent />
          </div>
        </Scrollbars>
      </div>
      <div className="desktop-hide">
        <div className="cartMain">
          <Row>
            <Col span={8}>
              <img
                src={LeftArrow}
                alt=""
                className="arrowLeft"
                onClick={handleBack}
              />
            </Col>
            <Col span={12}>
              <p className="cartTitle">Order History</p>
            </Col>
          </Row>
          <div style={{ marginTop: 25 }}>
            <div style={{ padding: "0px 10px" }}>
              <Row>
                <Col span={12}>
                  <p className="paymentDetails">Payment Option</p>
                </Col>
                <Col span={12}>
                  <p className="paymentDetailsValues">Credit Card</p>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <p className="paymentDetails">Date</p>
                </Col>
                <Col span={12}>
                  <p className="paymentDetailsValues">
                    {onlyDate} at {onlyTime}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <p className="paymentDetails">Shop</p>
                </Col>
                <Col span={12}>
                  <p className="paymentDetailsValues">{restaurant.name}</p>
                </Col>
              </Row>
            </div>
            <div style={{ textAlign: "center" }}>
              <p className="paymentConfirmation">Your payment confirmation</p>
              <QRCode
                size={128}
                value={QRvalue}
                imageSettings={{
                  x: null,
                  y: null,
                  height: 24,
                  width: 24,
                  excavate: true,
                }}
              />
              <p className="yourReceipt">Your receipt has been stored.</p>
            </div>
            <Card bodyStyle={{ padding: 15 }} className="orderDetailsCard">
              <Row>
                <Col span={12}>
                  <p className="OrderRestName">{selectedOrder.storeName}</p>
                </Col>
                <Col span={12}>
                  <p className="OrderDate">{selectedOrder.dateCreated}</p>
                </Col>
              </Row>
              <Scrollbars autoHide className="orderDetailsScroll">
                {selectedOrder &&
                  selectedOrder?.lineItems?.map((item, index) => (
                    <div>
                      <Row>
                        <Col span={17}>
                          <p className="OrderProdName">{item.name}</p>
                        </Col>
                        <Col span={3}>
                          <p className="OrderProdQty">{item.quantity}</p>
                        </Col>
                        <Col span={4}>
                          <p className="OrderProdPrice">&#8377; {item.price}</p>
                        </Col>
                      </Row>
                      {item?.addOnProducts?.map((addOnItem, addOnIndex) => (
                        <Row
                          style={{
                            display:
                              item?.addOnProducts?.length > 0
                                ? "block"
                                : "none",
                          }}
                        >
                          <Col span={24}>
                            <ul className="addonsList">
                              <li className="addonsProd">{addOnItem.name}</li>
                            </ul>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  ))}
              </Scrollbars>
              <Row gutter={[8, 0]} style={{ marginTop: 10 }}>
                <Col span={12}>
                  <Button className="reOrderBtn" size="large">
                    REORDER
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    className="rateOrderBtn"
                    size="large"
                    onClick={feedBack}
                  >
                    RATE ORDER
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreOrderHistory;
