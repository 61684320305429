import React, { useState, useEffect } from "react";
import { Row, Col, Card, Skeleton } from "antd";
import Texty from "rc-texty";
import "rc-texty/assets/index.css";
import QueueAnim from "rc-queue-anim";
import Animate from "rc-animate";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { fnbURL } from "../../../src/constants/config";
import { Scrollbars } from "react-custom-scrollbars";
import UserIcon from "../../assets/images/account-circle.svg";
import Ordered from "../../assets/images/ordered.svg";
import Prepared from "../../assets/images/prepared.svg";
import Collect from "../../assets/images/collect.svg";
import Collected from "../../assets/images/collected.svg";
import Banner from "../../assets/images/banner.png";
import BannerOne from "../../assets/images/banner1.png";
import BannerTwo from "../../assets/images/banner2.png";
import BuildingImg from "../../assets/images/building-line.png";
import "antd/dist/antd.css";
import "../styles.css";
import "../globalStyles.css";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";

// const { TabPane } = Tabs;

const Home = () => {
  const history = useHistory();
  const [restaurantsInfo, setRestaurantsInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    setLoading(true);
    const getStore = {
      query: `query {
        getStores(configId: "2EF21B71E41C4730B6046409F979CC17") {
            name
            promotionBanner
            announcement
            darkTheme
            storeConfig {
                name
                shortDescription
                storeImage
                storeId
                csBunit {
                    csBunitId
                    value
                    name
                }
                storeTimings {
                    startTime
                    endTime
                    isMonday
                    isTuesday
                    isWednesday
                    isThursday
                    isFriday
                    isSaturday
                    isSunday
                }
                storeHolidays {
                    name
                    holidayDate
                }
            }
        }
    }`,
    };
    Axios({
      url: fnbURL,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getStore,
      headers: {
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      const storeInfo = response.data.data.getStores;
      localStorage.setItem("storeInfo", JSON.stringify(storeInfo));
      if (storeInfo !== null) {
        let restaurants = storeInfo.storeConfig;
        setRestaurantsInfo([...restaurants]);
        setLoading(false);
      }
    });
  }, []);

  const handleRestForProducts = (key) => {
    setLoading(true);
    restaurantsInfo.map((data) => {
      if (key === data.storeId) {
        let restaurantId = data.storeId;
        getProducts(restaurantId);
      }
      return true;
    });
  };

  const getProducts = (restaurantId) => {
    const getProduct = {
      query: `query {
        getProducts(commerceConfigId: "${restaurantId}") {
          product_id
          category_id
          group_id
          location_id
          sku
          name
          short_desc
          veg
          unitprice
          listprice
          imageUrl
          bestseller
          available
          available_from
          available_to
          next_available_from
          next_available_to
          add_on_group {
            id
            type
            name
            minqty
            maxqty
            addOnItems {
              id
              name
              price
            }
          }
          attributeGroup {
            id
            name
            attribute {
              id
              value
              name
            }
          }
          ingredients
        }
      }`,
    };
    Axios({
      url: fnbURL,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getProduct,
      headers: {
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      if (response.data.data.getProducts) {
        const allProducts = response.data.data.getProducts;
        localStorage.setItem("restaurantId", restaurantId);
        localStorage.setItem("allProducts", JSON.stringify(allProducts));
        history.push("/restaurant");
      }
    });
  };

  const handleAccount = () => {
    history.push("/account");
  }

  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
      </div>
      <div className="mainDiv">
        <Scrollbars autoHide className="restCardsScroll">
          <Skeleton
            avatar
            loading={loading}
            style={{ padding: "20px 15px" }}
            paragraph={{ rows: 4 }}
          />
          <Skeleton
            avatar
            loading={loading}
            style={{ padding: "0px 15px" }}
            paragraph={{ rows: 4 }}
          />
          <Skeleton
            avatar
            loading={loading}
            style={{ padding: "0px 15px" }}
            paragraph={{ rows: 4 }}
          >
            <Row key="a" className="desktop-hide">
              <Col span={20}>
                <p className="greetingText">
                  {/* <Texty type="left" mode="sync"> */}
                  Hello,{" "}
                  {userInfo.firstName !== null ? userInfo.firstName : "CW"}
                  {/* </Texty> */}
                </p>
              </Col>
              <Col span={4}>
                <Animate transitionName="fade" transitionAppear>
                  <div key="1">
                    <img
                      src={UserIcon}
                      alt=""
                      className="userImage"
                      onClick={handleAccount}
                    />
                  </div>
                </Animate>
              </Col>
            </Row>
            <Row key="a" className="desktop-hide">
              <p className="whatDo">
                <Texty type="left" mode="sync">
                  What do you want to eat?
                </Texty>
              </p>
            </Row>
            <Row className="restaurantCardDiv mobile-hide">
              <Col span={8} style={{paddingRight: 3}}>
                <img src={BannerOne} alt="" className="bannerImg" />
              </Col>
              <Col span={8} style={{paddingRight: 3}}>
                <img src={BannerTwo} alt="" className="bannerImg" />
              </Col>
              <Col span={8}>
                <img src={Banner} alt="" className="bannerImg" />
              </Col>
            </Row>
            <Row className="mobile-hide" style={{ padding: "20px 40px", paddingBottom: 0 }}>
              <Col span={2}>
                <img
                  src={BuildingImg}
                  alt=""
                  className="buildingImg"
                  width="50%"
                />
              </Col>
              <Col span={22} style={{ alignSelf: "center" }}>
                <p className="addressTextDesk">
                  CTS Raheja Mindspace, Hyderabad
                </p>
              </Col>
            </Row>
            <Row className="restaurantCardDiv desktop-hide" key="a">
              <Col span={24}>
                <Animate transitionName="fade" transitionAppear>
                  <div key="1">
                    <Card bodyStyle={{ padding: 15 }} className="addressCard">
                      <QueueAnim key="queue" leaveReverse>
                        <div key="a">
                          <p className="addressText">CTS Raheja Mindspace,</p>
                          <p className="addressText">Hyderabad</p>
                        </div>
                      </QueueAnim>
                    </Card>
                  </div>
                </Animate>
              </Col>
            </Row>
            <div className="restaurantCardDiv">
              <Animate transitionName="fade" transitionAppear>
                <div key="1">
                  <Card bodyStyle={{ padding: 0 }} className="statusCard">
                    <Row>
                      <Col span={6}>
                        <QueueAnim key="queue" delay={300}>
                          <div key="a" className="queue-anim-demo">
                            <img src={Ordered} alt="" />
                            <p className="statusCardText">Ordered At 1:20 PM</p>
                          </div>
                        </QueueAnim>
                      </Col>
                      <Col span={6}>
                        <QueueAnim key="queue" delay={400}>
                          <div key="b" className="queue-anim-demo">
                            <img src={Prepared} alt="" />
                            <p className="statusCardText">
                              Food Is Being Prepared
                            </p>
                          </div>
                        </QueueAnim>
                      </Col>
                      <Col span={6}>
                        <QueueAnim key="queue" delay={500}>
                          <div key="c" className="queue-anim-demo">
                            <img src={Collect} alt="" />
                            <p className="statusCardText">Ready To Collect</p>
                          </div>
                        </QueueAnim>
                      </Col>
                      <Col span={6}>
                        <QueueAnim key="queue" delay={600}>
                          <div key="d" className="queue-anim-demo">
                            <img src={Collected} alt="" />
                            <p className="statusCardText">Food Collected</p>
                          </div>
                        </QueueAnim>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Animate>
            </div>
            <QueueAnim
              className="demo-content"
              delay={300}
              animConfig={[
                { opacity: [1, 0], translateY: [0, 50] },
                { opacity: [1, 0], translateY: [0, -50] },
              ]}
              type="bottom"
            >
              <div className="restaurantCardDiv desktop-hide" key="f">
                <Card bodyStyle={{ padding: 0 }} className="bannerCard">
                  <img src={Banner} alt="" className="bannerImg" />
                </Card>
              </div>
            </QueueAnim>
            {/* <QueueAnim className="demo-content" type="left" delay={300}>
              <div
                className="restaurantCardDiv"
                style={{ paddingBottom: 0 }}
                key="g"
              >
                <Tabs defaultActiveKey="1" className="floorTabs">
                  <TabPane
                    tab="Main Cafeteria"
                    key="1"
                    style={{ outline: "none" }}
                  ></TabPane>
                  <TabPane tab="Floor 1" key="2"></TabPane>
                  <TabPane tab="Floor 2" key="3"></TabPane>
                </Tabs>
              </div>
            </QueueAnim>  */}
            <div>
              <div className="restaurantCardsList">
                <QueueAnim
                  className="demo-content"
                  delay={300}
                  animConfig={[
                    { opacity: [1, 0], translateY: [0, 50] },
                    { opacity: [1, 0], translateY: [0, -50] },
                  ]}
                >
                  <div style={{ marginBottom: 35 }} key="h">
                    <p className="restaurantsTitle">Restaurants</p>
                    <Row>
                      {restaurantsInfo.map((item, index) => (
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 8 }}
                          xl={{ span: 8 }}
                          onClick={() => handleRestForProducts(item.storeId)}
                          style={{paddingRight: 5}}
                        >
                          <Card
                            bodyStyle={{ padding: 0 }}
                            className="restaurantCard"
                          >
                            <Animate transitionName="fade" transitionAppear>
                              <div key="1">
                                <img
                                  src={item.storeImage}
                                  alt=""
                                  className="restImg"
                                />
                              </div>
                            </Animate>
                          </Card>
                          <Card
                            bodyStyle={{ padding: 15 }}
                            className="restDetailsCard"
                          >
                            <QueueAnim name="QueueAnim" delay={300}>
                              <div key="a">
                                <Row>
                                  <Col span={20}>
                                    <p className="restName">{item.name}</p>
                                  </Col>
                                  <Col span={4}>
                                    <p className="restStatus">Open</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={14}>
                                    <p className="restItems">
                                      {item.shortDescription}
                                    </p>
                                  </Col>
                                  <Col span={10} style={{ paddingLeft: 10 }}>
                                    <p className="restClosing">
                                      Closes at 11:00 PM
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </QueueAnim>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </QueueAnim>
              </div>
            </div>
            <div className="mobile-hide">
              <FooterComponent />
            </div>
          </Skeleton>
        </Scrollbars>
      </div>
      <div className="desktop-hide">
        <MenuComponent />
      </div>
    </div>
  );
};

export default Home;
