import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Switch,
  Collapse,
  Popover,
  Checkbox,
  Modal,
} from "antd";
import {
  CaretRightOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import Animate from "rc-animate";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
import FilterIcon from "../../assets/images/filtericon.svg";
import HearBeatIcon from "../../assets/images/heartbeat.svg";
import DefaultImage from "../../assets/images/default-product-img.svg";
import RedHeart from "../../assets/images/heart-red.svg";
import FilterIconWhite from "../../assets/images/filter-white.svg";
import HearBeatIconWhite from "../../assets/images/heartbeat-white.svg";
// import GreyHeart from "../../assets/images/heart-grey.svg";
import "antd/dist/antd.css";
import "../styles.css";
import "../globalStyles.css";
import MenuComponent from "../menuComponent";
import _ from "lodash";
import FooterComponent from "../footer";
import AspectRatio from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";

const { Panel } = Collapse;

const Restaurant = () => {
  const history = useHistory();
  const [allProducts, setAllProducts] = useState([]);
  const [restaurant, setRestaurant] = useState({});
  const [cart, setCart] = useState({
    lineItems: [],
    total: 0,
    tax: 0,
    totalQty: 0,
  });

  useEffect(() => {
    const allProducts = JSON.parse(localStorage.getItem("allProducts"));
    const storeInfo = JSON.parse(localStorage.getItem("storeInfo"));
    const restaurantId = localStorage.getItem("restaurantId");
    const index = storeInfo.storeConfig.findIndex(
      (data) => data.storeId === restaurantId
    );
    const restaurant = storeInfo.storeConfig[index];
    const cartItems = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          lineItems: [],
          total: 0,
          tax: 0,
          totalQty: 0,
        };
    setCart(cartItems);
    setAllProducts(allProducts);
    setRestaurant(restaurant);
  }, []);

  // const [showHealthyMenu, setShowHealthyMenu] = useState(false);

  // const handleShowHealthyMenu = () => {
  //   setShowHealthyMenu(true);
  // };

  const handleHealthyMenu = (values) => {
    console.log("checked", values);
  };

  // const [showCategoryMenu, setShowCategoryMenu] = useState(false);

  // const handleShowCategoryMenu = () => {
  //   setShowCategoryMenu(true);
  // };

  const backToHome = () => {
    history.push("/home");
  };

  const setDefaultImage = (e) => {
    e.target.src = DefaultImage;
  };

  const addProdToCart = (prod, qty) => {
    console.log("prod", prod);
    const weight = parseFloat(qty);
    const addedToCart = [...cart.lineItems] || [];
    const index = addedToCart.findIndex(
      (p) => p.product_id === prod.product_id
    );
    let newObj =
      index >= 0
        ? {
            ...addedToCart[index],
            weight: addedToCart[index].weight + weight,
            add_on_group: addedToCart[index].add_on_group
              ? addedToCart[index].add_on_group
              : [],
            restaurantInfo: restaurant,
            imageUrl: prod.imageUrl,
            desc: prod.short_desc,
          }
        : {
            product_id: prod.product_id,
            name: prod.name,
            prodCode: prod.sku,
            price: Number(prod.listprice),
            total: 0,
            tax: 0,
            taxAmount: 0,
            taxRate: 0,
            weight: weight,
            add_on_group: prod.add_on_group ? prod.add_on_group : [],
            restaurantInfo: restaurant,
            imageUrl: prod.imageUrl,
            desc: prod.short_desc,
          };
    const addOnsPriceSum = _.sumBy(newObj?.add_on_group, (item) =>
      Number(item.price)
    );
    const mrp = (addOnsPriceSum + parseFloat(newObj.price)) * newObj.weight;
    const tax = mrp - mrp / (1 + newObj.taxRate / 100);
    newObj.taxAmount = tax;
    newObj.total = mrp;

    if (index >= 0) {
      if (newObj.weight === 0) {
        addedToCart.splice(index, 1);
      } else {
        addedToCart.splice(index, 1, newObj);
      }
    } else {
      addedToCart.push(newObj);
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    for (let i = 0; i < addedToCart.length; i++) {
      totalPrice += Number(addedToCart[i].total);
      totalItemsQty += addedToCart[i].weight;
      totalTax += Number(addedToCart[i].taxAmount);
      addedToCart[i].key = i;
    }

    let newCart = {
      ...cart,
      lineItems: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      totalQty: totalItemsQty,
    };

    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    localStorage.setItem("itemsQuantity", JSON.stringify(totalItemsQty));
  };

  const [selectedAddonObj, setSelectedAddonObj] = useState({});

  const handleAddons = (item) => {
    allProducts.map((data) => {
      if (item.product_id === data.product_id) {
        setSelectedAddonObj(item);
        localStorage.setItem("selectedItem", JSON.stringify(item));
        history.push("/add-ons");
      }
      return null;
    });
  };

  const [addonsSelectionModal, setAddonsSelectionModal] = useState(false);

  const selectedAllAdons = () => {
    let filteredItem = _.filter(
      [...cart.lineItems],
      (data) => data.product_id === selectedAddonObj.product_id
    );
    if (filteredItem.length > 0) {
      return _.map(filteredItem?.[0]?.add_on_group, (item) => `${item.name}, `);
    }
  };

  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
      </div>
      <div className="mobile-hide">
        <div>
          <Scrollbars autoHide className="prodCardsScrollDesk">
            <div
              style={
                {
                  // padding: "20px",
                  // background: `url(${restaurant.storeImage}) no-repeat cover`,
                  // backgroundSize: "cover",
                  // width: "100%",
                  // height: "100%",
                  // opacity: "0.8"
                }
              }
              className="restBg"
            >
              <Row>
                <Col span={5}>
                  <AspectRatio ratio="1">
                    <img
                      src={restaurant.storeImage}
                      alt=""
                      className="restImgDesk"
                    />
                  </AspectRatio>
                </Col>
                <Col span={19} style={{ paddingLeft: 60, alignSelf: "center" }}>
                  <Row>
                    <Col span={12}>
                      <p className="redNameDesk">{restaurant.name}</p>
                    </Col>
                    <Col span={12}>
                      <p className="redStatusDesk">Open</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p className="redDescDesk">
                        {restaurant.shortDescription}
                      </p>
                    </Col>
                    <Col span={12}>
                      <p className="redDescDeskRight">Closes at 11:00 PM</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <p className="redDescDesk">
                        Veg Only &nbsp;{" "}
                        <Switch
                          // defaultChecked
                          // onChange={onChange}
                          size="small"
                        />
                      </p>
                    </Col>
                    <Col span={8} style={{ textAlign: "center" }}>
                      <p className="redDescDesk">
                        Healthy &nbsp;{" "}
                        <Popover
                          getPopupContainer={(trigger) => trigger.parentElement}
                          content={
                            <div className="healthyMenu">
                              <Checkbox.Group
                                style={{ width: "100%" }}
                                onChange={handleHealthyMenu}
                              >
                                <Row>
                                  <Col span={24}>
                                    <Checkbox value="nutrition">
                                      Show Nutrition Values
                                    </Checkbox>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={24}>
                                    <Checkbox value="allergens">
                                      Show Allergens
                                    </Checkbox>
                                  </Col>
                                </Row>
                              </Checkbox.Group>
                            </div>
                          }
                          title={null}
                          trigger="click"
                          // visible={showHealthyMenu}
                          // onVisibleChange={handleShowHealthyMenu}
                          placement="bottomRight"
                        >
                          <img
                            src={HearBeatIconWhite}
                            alt=""
                            width="8%"
                            // className="heartIcon"
                          />
                        </Popover>
                      </p>
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <p className="redDescDesk">
                        Categories &nbsp;{" "}
                        <Popover
                          getPopupContainer={(trigger) => trigger.parentElement}
                          content={
                            <div className="healthyMenu">
                              <ul className="categoryUl">
                                <li className="categoryLi">Breakfast</li>
                                <li className="categoryLi">Lunch</li>
                                <li className="categoryLi">Dinner</li>
                                <li className="categoryLi">Snacks</li>
                              </ul>
                            </div>
                          }
                          title={null}
                          trigger="click"
                          // visible={showCategoryMenu}
                          // onVisibleChange={handleShowCategoryMenu}
                          placement="bottomRight"
                        >
                          <img
                            src={FilterIconWhite}
                            alt=""
                            width="8%"
                            // className="filterIcon"
                          />
                        </Popover>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="prodDivDesk">
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 270 : 90} />
                )}
                expandIconPosition="right"
                className="site-collapse-custom-collapse"
                accordion
                style={{ backgroundColor: "transparent" }}
              >
                <Panel
                  header={
                    <div>
                      <p className="collapseTitle">What's New</p>
                      <p className="ProdsCount">{allProducts?.length} Items</p>
                    </div>
                  }
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  {allProducts.map((item, index) => (
                    <Card bodyStyle={{ padding: 10 }} className="prodCardDesk">
                      <Row>
                        <Col span={3}>
                          <Animate transitionName="fade" transitionAppear>
                            <div key="1">
                              <img
                                src={item.imageUrl}
                                onError={setDefaultImage}
                                alt=""
                                className="prodImg"
                              />
                            </div>
                          </Animate>
                        </Col>
                        <Col span={21} style={{ paddingLeft: 10 }}>
                          <QueueAnim name="QueueAnim" delay={300}>
                            <div key="a">
                              <Row>
                                <Col span={20}>
                                  <p className="prodName">{item.name}</p>
                                </Col>
                                <Col span={4}>
                                  <img
                                    src={RedHeart}
                                    alt=""
                                    className="redHeart"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <p className="prodDesc">{item.short_desc}</p>
                                </Col>
                              </Row>
                              <Row style={{ paddingTop: 10 }}>
                                <Col span={21} style={{ alignSelf: "center" }}>
                                  <p className="prodPrice">
                                    &#8377; {item.listprice}
                                  </p>
                                </Col>
                                <Col span={3}>
                                  {cart?.lineItems?.length > 0 &&
                                  _.filter(
                                    [...cart.lineItems],
                                    (data) =>
                                      data.product_id === item.product_id &&
                                      data.weight > 0
                                  )?.length > 0 ? (
                                    <p>
                                      <span className="minusBtnDesk">
                                        <MinusOutlined
                                          disabled={
                                            _.filter(
                                              [...cart.lineItems],
                                              (data) =>
                                                data.product_id ===
                                                  item.product_id &&
                                                data.weight > 0
                                            )?.[0]?.weight === 0
                                          }
                                          onClick={() =>
                                            addProdToCart(item, -1)
                                          }
                                        />
                                      </span>
                                      &ensp;
                                      {
                                        _.filter(
                                          [...cart.lineItems],
                                          (data) =>
                                            data.product_id ===
                                              item.product_id && data.weight > 0
                                        )?.[0]?.weight
                                      }
                                      &ensp;
                                      <span className="plusBtnDesk">
                                        <PlusOutlined
                                          onClick={
                                            item.add_on_group !== null &&
                                            _.filter(
                                              [...cart.lineItems],
                                              (data) =>
                                                data.product_id ===
                                                  item.product_id &&
                                                data.weight > 0
                                            )?.[0]?.weight > 0
                                              ? () => {
                                                  setSelectedAddonObj(item);
                                                  setAddonsSelectionModal(true);
                                                }
                                              : () => addProdToCart(item, 1)
                                          }
                                        />
                                      </span>
                                    </p>
                                  ) : (
                                    <Button
                                      className="addButton"
                                      onClick={() =>
                                        item?.add_on_group !== null
                                          ? handleAddons(item)
                                          : addProdToCart(item, 1)
                                      }
                                    >
                                      {item?.add_on_group !== null
                                        ? "ADD +"
                                        : "ADD"}
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </QueueAnim>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </Panel>
                <Panel
                  header={
                    <div>
                      <p className="collapseTitle">Starters</p>
                      <p className="ProdsCount">{allProducts?.length} Items</p>
                    </div>
                  }
                  key="2"
                  className="site-collapse-custom-panel"
                ></Panel>
                <Panel
                  header={
                    <div>
                      <p className="collapseTitle">Main Course</p>
                      <p className="ProdsCount">{allProducts?.length} Items</p>
                    </div>
                  }
                  key="3"
                  className="site-collapse-custom-panel"
                ></Panel>
              </Collapse>
            </div>
            <div className="prodFooter">
              <FooterComponent />
            </div>
          </Scrollbars>
        </div>
      </div>
      <div className="desktop-hide">
        <div>
          <Scrollbars autoHide className="prodCardsScroll">
            <div>
              <Animate transitionName="fade" transitionAppear>
                <div key="1">
                  <img
                    src={restaurant.storeImage}
                    alt=""
                    className="restMainBg"
                  />
                  <img
                    src={LeftArrow}
                    alt=""
                    className="leftArrowIcon"
                    onClick={backToHome}
                  />
                </div>
              </Animate>
            </div>
            <div style={{ padding: 15 }} className="restInformCard">
              <QueueAnim
                className="demo-content"
                delay={400}
                animConfig={[
                  { opacity: [1, 0], translateY: [0, 50] },
                  { opacity: [1, 0], translateY: [0, -50] },
                ]}
              >
                <div key="b">
                  <Row>
                    <Col span={20}>
                      <p className="restName">{restaurant.name}</p>
                    </Col>
                    <Col span={4}>
                      <p className="restStatus">Open</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                      <p className="restItems">{restaurant.shortDescription}</p>
                    </Col>
                    <Col span={10} style={{ paddingLeft: 10 }}>
                      <p className="restClosing">Closes at 11:00 PM</p>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 20 }}>
                    <Col span={8}>
                      <p className="filtersOptionText">
                        Veg Only &nbsp;{" "}
                        <Switch
                          // defaultChecked
                          // onChange={onChange}
                          size="small"
                          className="vegSwitch"
                        />
                      </p>
                    </Col>
                    <Col span={8} style={{ textAlign: "center" }}>
                      <p className="filtersOptionText">
                        Healthy &nbsp;{" "}
                        <Popover
                          getPopupContainer={(trigger) => trigger.parentElement}
                          content={
                            <div className="healthyMenu">
                              <Checkbox.Group
                                style={{ width: "100%" }}
                                onChange={handleHealthyMenu}
                              >
                                <Row>
                                  <Col span={24}>
                                    <Checkbox value="nutrition">
                                      Show Nutrition Values
                                    </Checkbox>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={24}>
                                    <Checkbox value="allergens">
                                      Show Allergens
                                    </Checkbox>
                                  </Col>
                                </Row>
                              </Checkbox.Group>
                            </div>
                          }
                          title={null}
                          trigger="click"
                          // visible={showHealthyMenu}
                          // onVisibleChange={handleShowHealthyMenu}
                          placement="bottomRight"
                        >
                          <img src={HearBeatIcon} alt="" />
                        </Popover>
                      </p>
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <p className="filtersOptionText">
                        Categories &nbsp;{" "}
                        <Popover
                          getPopupContainer={(trigger) => trigger.parentElement}
                          content={
                            <div className="healthyMenu">
                              <ul className="categoryUl">
                                <li className="categoryLi">Breakfast</li>
                                <li className="categoryLi">Lunch</li>
                                <li className="categoryLi">Dinner</li>
                                <li className="categoryLi">Snacks</li>
                              </ul>
                            </div>
                          }
                          title={null}
                          trigger="click"
                          // visible={showCategoryMenu}
                          // onVisibleChange={handleShowCategoryMenu}
                          placement="bottomRight"
                        >
                          <img src={FilterIcon} alt="" />
                        </Popover>
                      </p>
                    </Col>
                  </Row>
                  <div style={{ marginBottom: 30 }}>
                    <Collapse
                      bordered={false}
                      defaultActiveKey={["1"]}
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 270 : 90} />
                      )}
                      expandIconPosition="right"
                      className="site-collapse-custom-collapse"
                      accordion
                    >
                      <Panel
                        header={
                          <div>
                            <p className="collapseTitle">What's New</p>
                            <p className="ProdsCount">
                              {allProducts?.length} Items
                            </p>
                          </div>
                        }
                        key="1"
                        className="site-collapse-custom-panel"
                      >
                        {allProducts.map((item, index) => (
                          <Card
                            bodyStyle={{ padding: 10 }}
                            className="prodCard"
                          >
                            <Row>
                              <Col span={8}>
                                <Animate transitionName="fade" transitionAppear>
                                  <div key="1">
                                    <img
                                      src={item.imageUrl}
                                      onError={setDefaultImage}
                                      alt=""
                                      className="prodImg"
                                    />
                                  </div>
                                </Animate>
                              </Col>
                              <Col span={16} style={{ paddingLeft: 10 }}>
                                <QueueAnim name="QueueAnim" delay={300}>
                                  <div key="a">
                                    <Row>
                                      <Col span={20}>
                                        <p className="prodName">{item.name}</p>
                                      </Col>
                                      <Col span={4}>
                                        <img
                                          src={RedHeart}
                                          alt=""
                                          className="redHeart"
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24}>
                                        <p className="prodDesc">
                                          {item.short_desc}
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row style={{ paddingTop: 10 }}>
                                      <Col
                                        span={15}
                                        style={{ alignSelf: "center" }}
                                      >
                                        <p className="prodPrice">
                                          &#8377; {item.listprice}
                                        </p>
                                      </Col>
                                      <Col span={9}>
                                        {cart?.lineItems?.length > 0 &&
                                        _.filter(
                                          [...cart.lineItems],
                                          (data) =>
                                            data.product_id ===
                                              item.product_id && data.weight > 0
                                        )?.length > 0 ? (
                                          <p className="IncDecBtns">
                                            <span className="minusBtn">
                                              <MinusOutlined
                                                disabled={
                                                  _.filter(
                                                    [...cart.lineItems],
                                                    (data) =>
                                                      data.product_id ===
                                                        item.product_id &&
                                                      data.weight > 0
                                                  )?.[0]?.weight === 0
                                                }
                                              />
                                            </span>
                                            &ensp;
                                            {
                                              _.filter(
                                                [...cart.lineItems],
                                                (data) =>
                                                  data.product_id ===
                                                    item.product_id &&
                                                  data.weight > 0
                                              )?.[0]?.weight
                                            }
                                            &ensp;
                                            <span className="plusBtn">
                                              <PlusOutlined
                                                onClick={
                                                  item.add_on_group !== null &&
                                                  _.filter(
                                                    [...cart.lineItems],
                                                    (data) =>
                                                      data.product_id ===
                                                        item.product_id &&
                                                      data.weight > 0
                                                  )?.[0]?.weight > 0
                                                    ? () => {
                                                        setSelectedAddonObj(
                                                          item
                                                        );
                                                        setAddonsSelectionModal(
                                                          true
                                                        );
                                                      }
                                                    : () =>
                                                        addProdToCart(item, 1)
                                                }
                                              />
                                            </span>
                                          </p>
                                        ) : (
                                          <Button
                                            className="addButton"
                                            onClick={() =>
                                              item?.add_on_group !== null
                                                ? handleAddons(item)
                                                : addProdToCart(item, 1)
                                            }
                                          >
                                            {item?.add_on_group !== null
                                              ? "ADD +"
                                              : "ADD"}
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </QueueAnim>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                      </Panel>
                      <Panel
                        header={
                          <div>
                            <p className="collapseTitle">Starters</p>
                            <p className="ProdsCount">
                              {allProducts?.length} Items
                            </p>
                          </div>
                        }
                        key="2"
                        className="site-collapse-custom-panel"
                      ></Panel>
                      <Panel
                        header={
                          <div>
                            <p className="collapseTitle">Main Course</p>
                            <p className="ProdsCount">
                              {allProducts?.length} Items
                            </p>
                          </div>
                        }
                        key="3"
                        className="site-collapse-custom-panel"
                      ></Panel>
                    </Collapse>
                  </div>
                </div>
              </QueueAnim>
            </div>
          </Scrollbars>
        </div>
        <MenuComponent />
      </div>
      <Modal
        title={null}
        visible={addonsSelectionModal}
        closable={false}
        footer={null}
        width="100%"
        bodyStyle={{ padding: 0 }}
        style={{
          margin: 0,
          maxWidth: "100vw",
          paddingBottom: 0,
        }}
      >
        <div>
          <div style={{ padding: 15 }} className="addOnCustomCard">
            <div>
              <Row>
                <Col span={24}>
                  <p className="itemName">{selectedAddonObj.name}</p>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <p className="itemPrice">
                    &#8377; {selectedAddonObj.listprice}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <div style={{ padding: 15 }}>
            <div>
              <p className="your-previous">Your previous customization</p>
              <p className="addons-selection">
                {selectedAddonObj && selectedAllAdons()}
              </p>
            </div>
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <Button
                  className="repeatCustomBtn"
                  onClick={() => {
                    addProdToCart(selectedAddonObj, 1);
                    setAddonsSelectionModal(false);
                  }}
                >
                  Repeat customization
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="chooseBtn"
                  onClick={() => {
                    handleAddons(selectedAddonObj);
                    setAddonsSelectionModal(false);
                  }}
                >
                  I'll Choose
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Restaurant;
