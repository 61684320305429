import React, {useEffect} from "react";
import Texty from 'rc-texty';
import Animate from "rc-animate";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import "../styles.css";

const Spaces = () => {
  const history = useHistory();
  useEffect(() => {
      setTimeout(() => {
        history.push('/login');
     }, 3000)
  }, [history])

  return (
    <Animate transitionName="fade" transitionAppear>
              <div key="1">
    <div className="spacesMain">
      <h1 className="spacesText"><Texty type="swing"
          mode="random">SPACES</Texty></h1>
    </div>
    </div>
          </Animate>
  );
};

export default Spaces;
