import React, { useState } from "react";
import { Row, Col, Card } from "antd";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
import LoyaltyImg from "../../assets/images/loyalty-icon.svg";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";

import BurgerImg from "../../assets/images/burger.png";
import Pizzahut from "../../assets/images/pizzahut.png";
import Paradise from "../../assets/images/paradise.png";
import Mehfill from "../../assets/images/mehfill.png";
import Urban from "../../assets/images/urban.png";

const Loyalty = () => {
  const history = useHistory();
	const [selectType, setSelectType] = useState("history");

  const handleSelection = (fieldName) => {
    setSelectType(fieldName);
  };

  const handleBack = () => {
    history.push("/restaurant");
  };

  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
      </div>
      <div className="mobile-hide">
        <Scrollbars autoHide className="prodCardsScrollDesk">
          <div className="cartDesk">
            <Row style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Row>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <img
                      src={LeftArrow}
                      alt=""
                      className="arrowLeft"
                      onClick={handleBack}
                    />
                  </Col>
                  <Col span={20}>
                    <p className="cartTitleDesk">Loyalty</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="prodFooter">
            <FooterComponent />
          </div>
        </Scrollbars>
      </div>
      <div className="desktop-hide">
        <div className="cartMain">
          <Row>
            <Col span={10}>
              <img
                src={LeftArrow}
                alt=""
                className="arrowLeft"
                onClick={handleBack}
              />
            </Col>
            <Col span={14}>
              <p className="cartTitle">Loyalty</p>
            </Col>
          </Row>
          <div style={{ marginTop: 10 }}>
            <Card bodyStyle={{ padding: 10 }} className="loyalty-card">
              <Row>
                <Col span={4}>
                  <img src={LoyaltyImg} alt="" width="100%" height="100%"/>
                </Col>
                <Col span={20} style={{ textAlign: "center", marginLeft: "-30px" }}>
                  <p className="loaylty-points">1000</p>
                  <p className="available-points">AVAILABLE POINTS</p>
                </Col>
              </Row>
              <Row gutter={[8, 8]} style={{ marginTop: 10 }}>
                <Col span={12}>
                  <Card
									className={selectType === "history" ? "active-select-loyalty" : "normal-select-loyalty"}
                    bodyStyle={{ padding: 10 }}
                    onClick={() => handleSelection("history")}
                  >
                    <p className={selectType === "history" ? "active-card-loyalty" : "normal-card-loyalty"}>HISTORY</p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    className={selectType === "offers" ? "active-select-loyalty" : "normal-select-loyalty"}
                    bodyStyle={{ padding: 10 }}
                    onClick={() => handleSelection("offers")}
                  >
                    <p className={selectType === "offers" ? "active-card-loyalty" : "normal-card-loyalty"}>OFFERS</p>
                  </Card>
                </Col>
              </Row>
            </Card>
          </div>
          <div>
            {selectType === "offers" && (
            <div style={{ marginTop: 10 }}>
              <Card bodyStyle={{ padding: 10 }} className="topup-trans-card">
                <Row>
                  <Col span={8}>
                    <img src={BurgerImg} alt="" />
                  </Col>
                  <Col span={16} style={{ alignSelf: "center" }}>
                    <p className="offer-name">60% OFF upto Rs.120</p>
                    <p className="offer-validity">Valid till 12-05-2022</p>
                    <p className="offer-code">
                      Use Code: <span className="super">SUPER60</span>
                    </p>
                  </Col>
                </Row>
              </Card>
							<Card bodyStyle={{ padding: 10 }} className="topup-trans-card">
                <Row>
                  <Col span={8}>
                    <img src={BurgerImg} alt="" />
                  </Col>
                  <Col span={16} style={{ alignSelf: "center" }}>
                    <p className="offer-name">60% OFF upto Rs.120</p>
                    <p className="offer-validity">Valid till 12-05-2022</p>
                    <p className="offer-code">
                      Use Code: <span className="super">SUPER60</span>
                    </p>
                  </Col>
                </Row>
              </Card>
            </div>
            )}
            {selectType === "history" && (
            <div style={{ marginTop: 10 }}>
              <p className="trans-history">Recent Activity</p>
              <Card
                bodyStyle={{ padding: "10px 15px" }}
                className="topup-trans-card"
              >
                <Row>
									<Col span={5}>
									<img src={Pizzahut} alt="" />
									</Col>
									<Col span={15}>
										<p className="you-earned">You earned 79 points</p>
										<p className="earned-amt">&#8377; 240.00 at pizzahut</p>
									</Col>
									<Col span={4} style={{alignSelf: "center"}}>
										<div className="earned-points"><span className="earned-points-text">+79</span></div>
									</Col>
								</Row>
              </Card>
							<Card
                bodyStyle={{ padding: "10px 15px" }}
                className="topup-trans-card"
              >
                <Row>
									<Col span={5}>
									<img src={Paradise} alt="" />
									</Col>
									<Col span={15}>
										<p className="you-earned">You earned 79 points</p>
										<p className="earned-amt">&#8377; 240.00 at Paradise</p>
									</Col>
									<Col span={4} style={{alignSelf: "center"}}>
										<div className="earned-points"><span className="earned-points-text">+79</span></div>
									</Col>
								</Row>
              </Card>
							<Card
                bodyStyle={{ padding: "10px 15px" }}
                className="topup-trans-card"
              >
                <Row>
									<Col span={5}>
									<img src={Mehfill} alt="" />
									</Col>
									<Col span={15}>
										<p className="you-earned">You earned 79 points</p>
										<p className="earned-amt">&#8377; 240.00 at Mehfil</p>
									</Col>
									<Col span={4} style={{alignSelf: "center"}}>
										<div className="earned-points"><span className="earned-points-text">+79</span></div>
									</Col>
								</Row>
              </Card>
							<Card
                bodyStyle={{ padding: "10px 15px" }}
                className="topup-trans-card"
              >
                <Row>
									<Col span={5}>
									<img src={Urban} alt="" />
									</Col>
									<Col span={15}>
										<p className="you-earned">You earned 79 points</p>
										<p className="earned-amt">&#8377; 240.00 at Urbankisaan</p>
									</Col>
									<Col span={4} style={{alignSelf: "center"}}>
										<div className="earned-points"><span className="earned-points-text">+79</span></div>
									</Col>
								</Row>
              </Card>
            </div>
            )}
          </div>
        </div>
      </div>
      <div className="desktop-hide">
        <MenuComponent />
      </div>
    </div>
  );
};

export default Loyalty;
