import React from "react";
import { Row, Col, Input } from "antd";
import { useHistory } from "react-router-dom";
import CWSuitLogo from "../../assets/images/cwsuite.png";
import Facebook from "../../assets/images/facebook.png";
import Twitter from "../../assets/images/twitter.png";
import Instagram from "../../assets/images/instagram.png";
import GooglePlus from "../../assets/images/google-plus.png";
import Master from "../../assets/images/master.png";
import Visa from "../../assets/images/visa-card.png";
import Paytm from "../../assets/images/paytm.svg";
import Mobikwik from "../../assets/images/mobikwik.png";
import Rupay from "../../assets/images/rupay.png";
import American from "../../assets/images/american.png";
import "antd/dist/antd.css";
import "../styles.css";
import "../globalStyles.css";

const FooterContent = () => {
  const history = useHistory();

  const handleHome = () => {
    history.push("/home");
  };
  return (
    <div>
      <Row>
        <Col span={2} />
        <Col span={4}>
          {/* <p className="spacesTextDesktop">SPACES</p> */}
          <img
            src={CWSuitLogo}
            alt=""
            width="50%"
            style={{ marginBottom: 16 }}
            onClick={handleHome}
          />
          <p className="lorem-ipsum">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos
          </p>
          <Row>
            <Col span={6} style={{ alignSelf: "center" }}>
              <img src={Facebook} alt="" />
            </Col>
            <Col span={6} style={{ alignSelf: "center" }}>
              <img src={Twitter} alt="" />
            </Col>
            <Col span={6} style={{ alignSelf: "center" }}>
              <img src={Instagram} alt="" />
            </Col>
            <Col span={6} style={{ alignSelf: "center" }}>
              <img src={GooglePlus} alt="" />
            </Col>
          </Row>
        </Col>
        <Col span={4} style={{ paddingLeft: 45 }}>
          <p className="footerTitle">About Us</p>
          <p className="footerMenu">About Us</p>
          <p className="footerMenu">Contact Us</p>
          <p className="footerMenu">About Team</p>
          <p className="footerMenu">Customer Support</p>
        </Col>
        <Col span={4} style={{ paddingLeft: 45 }}>
          <p className="footerTitle">Our Information</p>
          <p className="footerMenu">About Us</p>
          <p className="footerMenu">Contact Us</p>
          <p className="footerMenu">About Team</p>
          <p className="footerMenu">Customer Support</p>
        </Col>
        <Col span={4} style={{ paddingLeft: 45 }}>
          <p className="footerTitle">Community</p>
          <p className="footerMenu">About Us</p>
          <p className="footerMenu">Contact Us</p>
          <p className="footerMenu">About Team</p>
          <p className="footerMenu">Customer Support</p>
        </Col>
        <Col span={4}>
          <p className="footerTitle">Subscribe Now</p>
          <p className="footerMenu">
            Subscribe your email for newsletter and featured news based on you
            interest
          </p>
          <Input
            type="email"
            size="large"
            placeholder="Write your email here"
            className="emailInput"
          />
        </Col>
        <Col span={2} />
      </Row>
      <Row style={{ marginTop: 60 }}>
        <Col span={2} />
        <Col span={10} style={{ alignSelf: "center" }}>
          <p className="copyRight">
            COPYRIGHT &copy; 2021 CWSUITE - ALL RIGHTS RESERVED
          </p>
        </Col>
        <Col span={10}>
          <Row gutter={[8, 8]}>
            <Col span={4}>
              <img src={Master} alt="" className="socialImgs" />
            </Col>
            <Col span={4}>
              <img src={Visa} alt="" className="socialImgs" />
            </Col>
            <Col span={4}>
              <img src={Paytm} alt="" className="socialImgs" />
            </Col>
            <Col span={4}>
              <img src={Mobikwik} alt="" className="socialImgs" />
            </Col>
            <Col span={4}>
              <img src={Rupay} alt="" className="socialImgs" />
            </Col>
            <Col span={4}>
              <img src={American} alt="" className="socialImgs" />
            </Col>
          </Row>
        </Col>
        <Col span={2} />
      </Row>
    </div>
  );
};

export default FooterContent;
