import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Radio,
  Checkbox,
  message,
  Popover,
  Switch,
} from "antd";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
import Veg from "../../assets/images/veg.svg";
import Star from "../../assets/images/star.svg";
import FilterIconWhite from "../../assets/images/filter-white.svg";
import HearBeatIconWhite from "../../assets/images/heartbeat-white.svg";
import "antd/dist/antd.css";
import "../styles.css";
import "../globalStyles.css";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";
import _ from "lodash";

const AddOns = () => {
  const history = useHistory();

  const [cart, setCart] = useState({
    lineItems: [],
    total: 0,
    tax: 0,
    totalQty: 0,
  });
  const [restaurant, setRestaurant] = useState({});
  const [addOnsList, setAddOnsList] = useState({
    requiredList: [],
    optionsList: [],
  });
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedAddons, setSelectedAddon] = useState([]);

  useEffect(() => {
    const storeInfo = JSON.parse(localStorage.getItem("storeInfo"));
    const restaurantId = localStorage.getItem("restaurantId");
    const index = storeInfo.storeConfig.findIndex(
      (data) => data.storeId === restaurantId
    );
    const restaurant = storeInfo.storeConfig[index];
    const selectedItem = JSON.parse(localStorage.getItem("selectedItem"));
    console.log("selectedItem", selectedItem);
    const requiredListFilter = _.filter(
      [...selectedItem.add_on_group],
      (item) => item.minqty === "1" && item.maxqty === "1"
    );
    const optionsListFilter = _.filter(
      [...selectedItem.add_on_group],
      (item) => item.minqty === "1" && item.maxqty === "0"
    );
    setAddOnsList({
      requiredList: requiredListFilter || [],
      optionsList: optionsListFilter || [],
    });

    const cartItems = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          lineItems: [],
          total: 0,
          tax: 0,
          totalQty: 0,
        };
    setCart(cartItems);
    setSelectedProduct(selectedItem);
    setRestaurant(restaurant);

    let filteredItem = _.filter(
      [...cartItems.lineItems],
      (data) => data.product_id === selectedItem.product_id
    );
    console.log("newAddons filteredItem", filteredItem);
    if (filteredItem.length > 0) {
      console.log("newAddons", filteredItem?.[0]?.add_on_group || []);
      setSelectedAddon(filteredItem?.[0]?.add_on_group || []);
    }
  }, []);

  const backToRestaurants = () => {
    history.push("/restaurant");
  };

  const handleAddOnValue = (data, fieldName) => {
    let newSelectedAddons = [...selectedAddons];
    const indexValue =
      fieldName === "addOnRadio"
        ? _.findIndex(
            newSelectedAddons,
            (item) => item.addOnId === data.addOnId
          )
        : _.findIndex(newSelectedAddons, (item) => item.id === data.id);
    if (indexValue !== -1) {
      if (fieldName === "addOnRadio") {
        newSelectedAddons.splice(indexValue, 1, data);
      } else {
        if (data.checked) {
          newSelectedAddons[indexValue] = data;
        } else {
          newSelectedAddons.splice(indexValue, 1);
        }
      }
    } else {
      newSelectedAddons = [...selectedAddons, data];
    }
    setSelectedAddon(newSelectedAddons);
  };

  const addProdToCart = (prod, qty) => {
    const addedToCart = cart.lineItems || [];
    const index = addedToCart.findIndex(
      (p) => p.product_id === prod.product_id
    );
    const weight = parseFloat(qty);

    let newObj =
      index >= 0
        ? {
            ...addedToCart[index],
            weight: addedToCart[index].weight + weight,
            // add_on_group: addedToCart[index].add_on_group
            //   ? addedToCart[index].add_on_group
            //   : [],
            add_on_group: prod.add_on_group ? prod.add_on_group : [],
            restaurantInfo: restaurant,
            imageUrl: prod.imageUrl,
            desc: prod.short_desc,
          }
        : {
            product_id: prod.product_id,
            name: prod.name,
            prodCode: prod.sku,
            price: prod.listprice,
            total: 0,
            tax: 0,
            taxAmount: 0,
            taxRate: 0,
            weight: weight,
            add_on_group: prod.add_on_group ? prod.add_on_group : [],
            restaurantInfo: restaurant,
            imageUrl: prod.imageUrl,
            desc: prod.short_desc,
          };
    const addOnsPriceSum = _.sumBy(newObj?.add_on_group, (item) =>
      Number(item.price)
    );
    const mrp = (addOnsPriceSum + parseFloat(newObj.price)) * newObj.weight;
    const tax = mrp - mrp / (1 + newObj.taxRate / 100);
    newObj.taxAmount = tax;
    newObj.total = mrp;

    if (index >= 0) {
      if (newObj.weight === 0) {
        addedToCart.splice(index, 1);
      } else {
        addedToCart.splice(index, 1, newObj);
      }
    } else {
      addedToCart.push(newObj);
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    for (let i = 0; i < addedToCart.length; i++) {
      totalPrice += Number(addedToCart[i].total);
      totalItemsQty += addedToCart[i].weight;
      totalTax += Number(addedToCart[i].taxAmount);
      addedToCart[i].key = i;
    }

    let newCart = {
      ...cart,
      lineItems: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      totalQty: totalItemsQty,
    };

    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    localStorage.setItem("itemsQuantity", JSON.stringify(totalItemsQty));
  };

  const handleContinue = async (values) => {
    await addProdToCart(
      { ...selectedProduct, add_on_group: [...selectedAddons] || [] },
      1
    );
    history.push("/restaurant");
  };

  // const [showHealthyMenu, setShowHealthyMenu] = useState(false);

  // const handleShowHealthyMenu = () => {
  //   setShowHealthyMenu(true);
  // };

  const handleHealthyMenu = (values) => {
    console.log("checked", values);
  };

  // const [showCategoryMenu, setShowCategoryMenu] = useState(false);

  // const handleShowCategoryMenu = () => {
  //   setShowCategoryMenu(true);
  // };

  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
      </div>
      <div className="mobile-hide">
        <div>
          <Scrollbars autoHide className="prodCardsScrollDesk">
            <div
              style={
                {
                  // padding: "20px",
                  // background: `url(${restaurant.storeImage}) no-repeat cover`,
                  // backgroundSize: "cover",
                  // width: "100%",
                  // height: "100%",
                  // opacity: "0.8"
                }
              }
              className="restBg"
            >
              <Row>
                <Col span={5}>
                  <img
                    src={selectedProduct.imageUrl}
                    alt=""
                    className="restImgDesk"
                  />
                </Col>
                <Col span={19} style={{ paddingLeft: 60, alignSelf: "center" }}>
                  <Row>
                    <Col span={20}>
                      <p className="redNameDesk">{selectedProduct.name}</p>
                    </Col>
                    <Col span={4}>
                      <img src={Veg} alt="" className="vegImgDesk" />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <p className="redDescDesk">
                        {selectedProduct.short_desc}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <p className="redDescDesk">
                        Rs.{selectedProduct.listprice}
                      </p>
                    </Col>
                    <Col span={8} style={{ textAlign: "center" }}>
                      <p className="redDescDesk">
                        4.5 <img src={Star} alt="" className="starImg" />
                      </p>
                    </Col>
                    <Col span={8} />
                  </Row>
                  <Row>
                    <Col span={8}>
                      <p className="redDescDesk">
                        Veg Only &nbsp;{" "}
                        <Switch
                          // defaultChecked
                          // onChange={onChange}
                          size="small"
                        />
                      </p>
                    </Col>
                    <Col span={8} style={{ textAlign: "center" }}>
                      <p className="redDescDesk">
                        Healthy &nbsp;{" "}
                        <Popover
                          getPopupContainer={(trigger) => trigger.parentElement}
                          content={
                            <div className="healthyMenu">
                              <Checkbox.Group
                                style={{ width: "100%" }}
                                onChange={handleHealthyMenu}
                              >
                                <Row>
                                  <Col span={24}>
                                    <Checkbox value="nutrition">
                                      Show Nutrition Values
                                    </Checkbox>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={24}>
                                    <Checkbox value="allergens">
                                      Show Allergens
                                    </Checkbox>
                                  </Col>
                                </Row>
                              </Checkbox.Group>
                            </div>
                          }
                          title={null}
                          trigger="click"
                          // visible={showHealthyMenu}
                          // onVisibleChange={handleShowHealthyMenu}
                          placement="bottomRight"
                        >
                          <img
                            src={HearBeatIconWhite}
                            alt=""
                            width="8%"
                            // className="heartIcon"
                          />
                        </Popover>
                      </p>
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <p className="redDescDesk">
                        Categories &nbsp;{" "}
                        <Popover
                          getPopupContainer={(trigger) => trigger.parentElement}
                          content={
                            <div className="healthyMenu">
                              <ul className="categoryUl">
                                <li className="categoryLi">Breakfast</li>
                                <li className="categoryLi">Lunch</li>
                                <li className="categoryLi">Dinner</li>
                                <li className="categoryLi">Snacks</li>
                              </ul>
                            </div>
                          }
                          title={null}
                          trigger="click"
                          // visible={showCategoryMenu}
                          // onVisibleChange={handleShowCategoryMenu}
                          placement="bottomRight"
                        >
                          <img
                            src={FilterIconWhite}
                            alt=""
                            width="8%"
                            // className="filterIcon"
                          />
                        </Popover>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="addOnDivDesk">
              <div>
                {/* <Scrollbars autoHide className="addOnsScroll"> */}
                {addOnsList?.requiredList?.length > 0
                  ? Object.entries(
                      _.groupBy(addOnsList?.requiredList, "name")
                    ).map(([key, value]) => (
                      <div style={{ marginBottom: 10 }}>
                        <div style={{ padding: "0px 15px" }}>
                          <p className="addOnsChoose">
                            Choose Your {key || ""}
                            {value.length > 0 &&
                              value[0].minqty === "1" &&
                              value[0].maxqty === "1" && (
                                <span className="requiredText">{` (required)`}</span>
                              )}
                          </p>
                          <hr className="bottomLine" />
                        </div>
                        {value.map((valueItem, valueIndex) => (
                          <Radio.Group
                            style={{ width: "100%" }}
                            value={
                              _.intersection(
                                _.map(valueItem.addOnItems, "id"),
                                _.map(selectedAddons, "id")
                              )?.[0] || ""
                            }
                            onChange={(e) => {
                              let filterData = _.filter(
                                valueItem.addOnItems || [],
                                (item) => item.id === e.target.value
                              );
                              filterData.length > 0 &&
                                handleAddOnValue(
                                  {
                                    ...filterData?.[0],
                                    addOnId: valueItem.id,
                                    addOnMaxqty: valueItem.maxqty,
                                    addOnMinqty: valueItem.minqty,
                                    addOnName: valueItem.name,
                                    addOnType: valueItem.type,
                                  },
                                  "addOnRadio"
                                );
                            }}
                          >
                            {valueItem.addOnItems.map(
                              (addonItemData, index) => (
                                <Row style={{ padding: "0px 15px" }}>
                                  <Col span={20}>
                                    <Radio value={addonItemData.id}>
                                      <span className="addOnItem">
                                        {addonItemData.name}
                                      </span>
                                    </Radio>
                                  </Col>
                                  <Col span={4}>
                                    <p className="addOnItemPrice">
                                      + &#8377; {addonItemData.price}
                                    </p>
                                  </Col>
                                </Row>
                              )
                            )}
                          </Radio.Group>
                        ))}
                      </div>
                    ))
                  : ""}
                {addOnsList?.optionsList?.length > 0
                  ? Object.entries(
                      _.groupBy(addOnsList?.optionsList, "name")
                    ).map(([key, value]) => (
                      <div style={{ marginBottom: 10 }}>
                        {" "}
                        <div style={{ padding: "0px 15px" }}>
                          <p className="addOnsChoose">
                            Choose Your {key || ""}{" "}
                            <span className="requiredText">{` (optional)`}</span>
                          </p>
                          <hr className="bottomLine" />
                        </div>
                        {value.map((valueItem, valueIndex) =>
                          valueItem.addOnItems.map((item, index) => (
                            <Row style={{ padding: "0px 15px" }}>
                              <Col span={20}>
                                <Checkbox
                                  checked={
                                    selectedAddons.findIndex(
                                      (itemAddOn) => itemAddOn.id === item.id
                                    ) !== -1
                                  }
                                  value={item}
                                  onChange={(e) =>
                                    handleAddOnValue(
                                      {
                                        ...e.target.value,
                                        addOnId: valueItem.id,
                                        addOnMaxqty: valueItem.maxqty,
                                        addOnMinqty: valueItem.minqty,
                                        addOnName: valueItem.name,
                                        addOnType: valueItem.type,
                                        checked: e.target.checked,
                                      },
                                      "addOnCheck"
                                    )
                                  }
                                >
                                  {" "}
                                  <span>{item.name}</span>
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <span>+ &#8377; {item.price}</span>
                              </Col>
                            </Row>
                          ))
                        )}
                      </div>
                    ))
                  : ""}
                {/* </Scrollbars> */}
              </div>
              <div style={{ marginTop: "2rem" }}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    size="large"
                    htmlType="submit"
                    className="continueBtn"
                    onClick={() => {
                      let requiredAlert = [];
                      Object.entries(
                        _.groupBy(addOnsList?.requiredList, "name")
                      ).map(([key, value]) =>
                        value.map((valueItem) => {
                          let intersectionData = _.intersection(
                            _.map(valueItem.addOnItems, "id"),
                            _.map(selectedAddons, "id")
                          );
                          intersectionData.length === 0 &&
                            requiredAlert.push(...valueItem.addOnItems);
                          return null;
                        })
                      );
                      if (requiredAlert.length > 0) {
                        message.error("Please select required fields");
                      } else {
                        handleContinue();
                      }
                    }}
                    disabled={selectedAddons.length === 0}
                  >
                    Continue
                  </Button>
                </Form.Item>
              </div>
            </div>
            <div className="prodFooter">
            <FooterComponent />
            </div>
          </Scrollbars>
        </div>
      </div>
      <div className="desktop-hide">
        <div>
          <div>
            <img src={selectedProduct.imageUrl} alt="" className="restMainBg" />
            <img
              src={LeftArrow}
              alt=""
              className="leftArrowIcon"
              onClick={backToRestaurants}
            />
          </div>
          <Card bodyStyle={{ padding: 15 }} className="itemDetailsCard">
            <Row>
              <Col span={20}>
                <p className="itemName">{selectedProduct.name}</p>
              </Col>
              <Col span={4}>
                <img src={Veg} alt="" className="vegImg" />
              </Col>
            </Row>
            <Row>
              <Col span={20}>
                <p className="itemPrice">Rs.{selectedProduct.listprice}</p>
              </Col>
              <Col span={4}>
                <p className="itemRating">
                  4.5 <img src={Star} alt="" className="starImg" />
                </p>
              </Col>
            </Row>
          </Card>
          <div>
            <Scrollbars autoHide className="addOnsScroll">
              {addOnsList?.requiredList?.length > 0
                ? Object.entries(
                    _.groupBy(addOnsList?.requiredList, "name")
                  ).map(([key, value]) => (
                    <div style={{ marginBottom: 10 }}>
                      <div style={{ padding: "0px 15px" }}>
                        <p className="addOnsChoose">
                          Choose Your {key || ""}
                          {value.length > 0 &&
                            value[0].minqty === "1" &&
                            value[0].maxqty === "1" && (
                              <span className="requiredText">{` (required)`}</span>
                            )}
                        </p>
                        <hr className="bottomLine" />
                      </div>
                      {value.map((valueItem, valueIndex) => (
                        <Radio.Group
                          style={{ width: "100%" }}
                          value={
                            _.intersection(
                              _.map(valueItem.addOnItems, "id"),
                              _.map(selectedAddons, "id")
                            )?.[0] || ""
                          }
                          onChange={(e) => {
                            let filterData = _.filter(
                              valueItem.addOnItems || [],
                              (item) => item.id === e.target.value
                            );
                            filterData.length > 0 &&
                              handleAddOnValue(
                                {
                                  ...filterData?.[0],
                                  addOnId: valueItem.id,
                                  addOnMaxqty: valueItem.maxqty,
                                  addOnMinqty: valueItem.minqty,
                                  addOnName: valueItem.name,
                                  addOnType: valueItem.type,
                                },
                                "addOnRadio"
                              );
                          }}
                        >
                          {valueItem.addOnItems.map((addonItemData, index) => (
                            <Row style={{ padding: "0px 15px" }}>
                              <Col span={20}>
                                <Radio value={addonItemData.id}>
                                  <span className="addOnItem">
                                    {addonItemData.name}
                                  </span>
                                </Radio>
                              </Col>
                              <Col span={4}>
                                <p className="addOnItemPrice">
                                  + &#8377; {addonItemData.price}
                                </p>
                              </Col>
                            </Row>
                          ))}
                        </Radio.Group>
                      ))}
                    </div>
                  ))
                : ""}
              {addOnsList?.optionsList?.length > 0
                ? Object.entries(
                    _.groupBy(addOnsList?.optionsList, "name")
                  ).map(([key, value]) => (
                    <div style={{ marginBottom: 10 }}>
                      {" "}
                      <div style={{ padding: "0px 15px" }}>
                        <p className="addOnsChoose">
                          Choose Your {key || ""}{" "}
                          <span className="requiredText">{` (optional)`}</span>
                        </p>
                        <hr className="bottomLine" />
                      </div>
                      {value.map((valueItem, valueIndex) =>
                        valueItem.addOnItems.map((item, index) => (
                          <Row style={{ padding: "0px 15px" }}>
                            <Col span={20}>
                              <Checkbox
                                checked={
                                  selectedAddons.findIndex(
                                    (itemAddOn) => itemAddOn.id === item.id
                                  ) !== -1
                                }
                                value={item}
                                onChange={(e) =>
                                  handleAddOnValue(
                                    {
                                      ...e.target.value,
                                      addOnId: valueItem.id,
                                      addOnMaxqty: valueItem.maxqty,
                                      addOnMinqty: valueItem.minqty,
                                      addOnName: valueItem.name,
                                      addOnType: valueItem.type,
                                      checked: e.target.checked,
                                    },
                                    "addOnCheck"
                                  )
                                }
                              >
                                {" "}
                                <span>{item.name}</span>
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <span>+ &#8377; {item.price}</span>
                            </Col>
                          </Row>
                        ))
                      )}
                    </div>
                  ))
                : ""}
            </Scrollbars>
          </div>
        </div>
        <div className="continueBtnDivInCart" style={{ marginTop: 10 }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              size="large"
              htmlType="submit"
              className="continueBtn"
              onClick={() => {
                let requiredAlert = [];
                Object.entries(_.groupBy(addOnsList?.requiredList, "name")).map(
                  ([key, value]) =>
                    value.map((valueItem) => {
                      let intersectionData = _.intersection(
                        _.map(valueItem.addOnItems, "id"),
                        _.map(selectedAddons, "id")
                      );
                      intersectionData.length === 0 &&
                        requiredAlert.push(...valueItem.addOnItems);
                      return null;
                    })
                );
                if (requiredAlert.length > 0) {
                  message.error("Please select required fields");
                } else {
                  handleContinue();
                }
              }}
              disabled={selectedAddons.length === 0}
            >
              Continue
            </Button>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default AddOns;
