import React from "react";
import { Row, Col, Card, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
import DefaultImage from "../../assets/images/default-product-img.svg";
import RedHeart from "../../assets/images/heart-red.svg";
import GreyHeart from "../../assets/images/heart-grey.svg";
import DishOne from "../../assets/images/dish1.png";
import DishTwo from "../../assets/images/dish2.png";
import TrashBin from "../../assets/images/trash-bin.svg";
import Closegrey from "../../assets/images/close-grey.svg";
import "antd/dist/antd.css";
import "../styles.css";
import "../globalStyles.css";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";

const Favourites = () => {
  const history = useHistory();
  const handleHome = () => {
    history.push("/home");
  };
  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
      </div>
      <div className="mobile-hide">
        <Scrollbars autoHide className="prodCardsScrollDesk">
          <div className="cartDesk">
            <Row style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Row>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <img
                      src={LeftArrow}
                      alt=""
                      className="arrowLeft"
                      onClick={handleHome}
                    />
                  </Col>
                  <Col span={20}>
                    <p className="cartTitleDesk">Favourites</p>
                  </Col>
                </Row>
              </Col>
              <Col
                span={12}
                style={{ textAlign: "right", alignSelf: "center" }}
              >
                <p
                  className="clearAll"
                  // onClick={() =>
                  //   cart.lineItems.length > 0 ? deleteCart() : ""
                  // }
                >
                  <img
                    src={TrashBin}
                    alt=""
                    // onClick={handleBack}
                    width="3%"
                  />{" "}
                  &nbsp; Clear All &nbsp;
                  <img
                    src={Closegrey}
                    alt=""
                    // onClick={handleBack}
                    width="2.5%"
                  />
                </p>
              </Col>
            </Row>
            <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={3}>
                      <img src={DefaultImage} alt="" />
                    </Col>
                    <Col span={21} style={{ paddingLeft: 15 }}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">
                            Spicy Meatball & Green Bean
                          </p>
                        </Col>
                        <Col span={4}>
                          <img src={RedHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={21} style={{ alignSelf: "center" }}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                        <Col span={3}>
                          <Button className="addButton">ADD</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">Lighter Beef & Squash Stew</p>
                        </Col>
                        <Col span={4}>
                          <img src={GreyHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={21} style={{ alignSelf: "center" }}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                        <Col span={3}>
                          <Button className="addButton">ADD</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={3}>
                      <img src={DishOne} alt="" />
                    </Col>
                    <Col span={21} style={{ paddingLeft: 15 }}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">Homemade Chicken Goujon</p>
                        </Col>
                        <Col span={4}>
                          <img src={RedHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={24}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 10 }}>
                    <Col span={21}>
                      <Row>
                        <Col span={3} style={{ marginRight: 5 }}>
                          <Card
                            size="small"
                            className="nutriInfo"
                            bodyStyle={{ padding: 4 }}
                          >
                            612 kcal
                          </Card>
                        </Col>
                        <Col span={3} style={{ marginRight: 5 }}>
                          <Card
                            size="small"
                            className="nutriInfo"
                            bodyStyle={{ padding: 4 }}
                          >
                            High Protein
                          </Card>
                        </Col>
                        <Col span={3} style={{ marginRight: 5 }}>
                          <Card
                            size="small"
                            className="nutriInfo"
                            bodyStyle={{ padding: 4 }}
                          >
                            High Fibre
                          </Card>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        <Col span={2} className="nutriInfoDetailsCol">
                          <p className="nutriWgt">30 g</p>
                          <p className="nutriTitle">Protein</p>
                        </Col>
                        <Col span={2} className="nutriInfoDetailsCol">
                          <p className="nutriWgt">49 g</p>
                          <p className="nutriTitle">Carbs</p>
                        </Col>
                        <Col span={2} className="nutriInfoDetailsCol">
                          <p className="nutriWgt">30 g</p>
                          <p className="nutriTitle">Fats</p>
                        </Col>
                        <Col span={2}>
                          <p className="nutriWgt">30 g</p>
                          <p className="nutriTitle">Fibre</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={3} style={{ alignSelf: "end" }}>
                      <Button className="addButton">ADD</Button>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={3}>
                      <img src={DishTwo} alt="" />
                    </Col>
                    <Col span={21} style={{ paddingLeft: 15 }}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">
                            Simply Perfect Beef Spag Bol
                          </p>
                        </Col>
                        <Col span={4}>
                          <img src={RedHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={21} style={{ alignSelf: "center" }}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                        <Col span={3}>
                          <Button className="addButton">ADD</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={3}>
                      <img src={DishTwo} alt="" />
                    </Col>
                    <Col span={21} style={{ paddingLeft: 15 }}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">
                            Simply Perfect Beef Spag Bol
                          </p>
                        </Col>
                        <Col span={4}>
                          <img src={RedHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={21} style={{ alignSelf: "center" }}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                        <Col span={3}>
                          <Button className="addButton">ADD</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={3}>
                      <img src={DishOne} alt="" />
                    </Col>
                    <Col span={21} style={{ paddingLeft: 15 }}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">Homemade Chicken Goujon</p>
                        </Col>
                        <Col span={4}>
                          <img src={RedHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={21} style={{ alignSelf: "center" }}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                        <Col span={3}>
                          <Button className="addButton">
                            ADD <PlusOutlined style={{ color: "#ffffff" }} />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
          </div>
          <div className="prodFooter">
          <FooterComponent />
          </div>
        </Scrollbars>
      </div>
      <div className="desktop-hide">
        <div className="cartMain">
          <Row>
            <Col span={24}>
              <img
                src={LeftArrow}
                alt=""
                className="arrowLeft"
                onClick={handleHome}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className="your-favourites">Your Favorites</p>
            </Col>
          </Row>
          <div style={{ marginBottom: 30 }}>
            <Scrollbars autoHide className="favouritesScroll">
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={8}>
                      <img src={DefaultImage} alt="" />
                    </Col>
                    <Col span={16} style={{ paddingLeft: 15 }}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">
                            Spicy Meatball & Green Bean
                          </p>
                        </Col>
                        <Col span={4}>
                          <img src={RedHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={13} style={{ alignSelf: "center" }}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                        <Col span={11}>
                          <Button className="addButton">ADD</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">Lighter Beef & Squash Stew</p>
                        </Col>
                        <Col span={4}>
                          <img src={GreyHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={17} style={{ alignSelf: "center" }}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                        <Col span={7}>
                          <Button className="addButton">ADD</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={8}>
                      <img src={DishOne} alt="" />
                    </Col>
                    <Col span={16} style={{ paddingLeft: 15 }}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">Homemade Chicken Goujon</p>
                        </Col>
                        <Col span={4}>
                          <img src={RedHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={24}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 10 }}>
                    <Col span={17}>
                      <Row>
                        <Col span={7} style={{ marginRight: 5 }}>
                          <Card
                            size="small"
                            className="nutriInfo"
                            bodyStyle={{ padding: 4 }}
                          >
                            612 kcal
                          </Card>
                        </Col>
                        <Col span={7} style={{ marginRight: 5 }}>
                          <Card
                            size="small"
                            className="nutriInfo"
                            bodyStyle={{ padding: 4 }}
                          >
                            High Protein
                          </Card>
                        </Col>
                        <Col span={7} style={{ marginRight: 5 }}>
                          <Card
                            size="small"
                            className="nutriInfo"
                            bodyStyle={{ padding: 4 }}
                          >
                            High Fibre
                          </Card>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        <Col span={5} className="nutriInfoDetailsCol">
                          <p className="nutriWgt">30 g</p>
                          <p className="nutriTitle">Protein</p>
                        </Col>
                        <Col span={5} className="nutriInfoDetailsCol">
                          <p className="nutriWgt">49 g</p>
                          <p className="nutriTitle">Carbs</p>
                        </Col>
                        <Col span={5} className="nutriInfoDetailsCol">
                          <p className="nutriWgt">30 g</p>
                          <p className="nutriTitle">Fats</p>
                        </Col>
                        <Col span={5}>
                          <p className="nutriWgt">30 g</p>
                          <p className="nutriTitle">Fibre</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={7} style={{ alignSelf: "end" }}>
                      <Button className="addButton">ADD</Button>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={8}>
                      <img src={DishTwo} alt="" />
                    </Col>
                    <Col span={16} style={{ paddingLeft: 15 }}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">
                            Simply Perfect Beef Spag Bol
                          </p>
                        </Col>
                        <Col span={4}>
                          <img src={RedHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={13} style={{ alignSelf: "center" }}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                        <Col span={11}>
                          <Button className="addButton">ADD</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={8}>
                      <img src={DishTwo} alt="" />
                    </Col>
                    <Col span={16} style={{ paddingLeft: 15 }}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">
                            Simply Perfect Beef Spag Bol
                          </p>
                        </Col>
                        <Col span={4}>
                          <img src={RedHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={13} style={{ alignSelf: "center" }}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                        <Col span={11}>
                          <Button className="addButton">ADD</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div style={{ padding: 5 }}>
                <Card bodyStyle={{ padding: 10 }} className="favProdCard">
                  <Row>
                    <Col span={8}>
                      <img src={DishOne} alt="" />
                    </Col>
                    <Col span={16} style={{ paddingLeft: 15 }}>
                      <Row>
                        <Col span={20}>
                          <p className="prodName">Homemade Chicken Goujon</p>
                        </Col>
                        <Col span={4}>
                          <img src={RedHeart} alt="" className="redHeart" />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p className="prodDesc">
                            For this Moroccan-style stew, you'll roll homemade
                            meatballs ..More
                          </p>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col span={13} style={{ alignSelf: "center" }}>
                          <p className="prodPrice">&#8377; 599</p>
                        </Col>
                        <Col span={11}>
                          <Button className="addButton">
                            ADD <PlusOutlined style={{ color: "#ffffff" }} />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Scrollbars>
          </div>
        </div>
        <MenuComponent />
      </div>
    </div>
  );
};

export default Favourites;
