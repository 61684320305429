const firebaseConfig = {
    apiKey: "AIzaSyC2cPCeB6efS_u_JMjxfsUTbxQRztp2lg4",
    authDomain: "evolution-cw.firebaseapp.com",
    databaseURL: "https://evolution-cw.firebaseio.com",
    projectId: "evolution-cw",
    storageBucket: "evolution-cw.appspot.com",
    messagingSenderId: "1040440208515",
    appId: "1:1040440208515:web:8b9b017b0776872f1dffb6",
    measurementId: "G-D88KDDWLKN"
};

export { firebaseConfig };